import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const TipoCliente = () => {
  const [tipos, setTipos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [tipoForm, setTipoForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar tipos de clientes com token JWT
  useEffect(() => {
    loadTiposClientes();
  }, []);

  const loadTiposClientes = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/tipo-cliente`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setTipos(response.data))
      .catch(() => setErrorMessage('Erro ao carregar tipos de clientes.'));
  };

  // Função para abrir o modal
  const handleOpenModal = (type, tipo = null) => {
    setModalType(type);
    if (tipo) {
      setSelectedTipo(tipo);
      setTipoForm({ nome: tipo.nome });
    } else {
      setSelectedTipo(null);
      setTipoForm({ nome: '' });
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTipo(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar tipo de cliente
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!tipoForm.nome) {
      setErrorMessage('O nome do tipo de cliente é obrigatório.');
      return;
    }

    const formData = { nome: tipoForm.nome };

    if (modalType === 'edit' && selectedTipo) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/tipo-cliente/${selectedTipo.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Tipo de cliente atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadTiposClientes();
        })
        .catch(() => setErrorMessage('Erro ao atualizar tipo de cliente.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/tipo-cliente`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Tipo de cliente criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadTiposClientes();
        })
        .catch(() => setErrorMessage('Erro ao criar tipo de cliente.'));
    }
  };

  // Função para deletar tipo de cliente
  const handleDeleteTipoCliente = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/tipo-cliente/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Tipo de cliente deletado com sucesso.');
        setShowSnackbar(true);
        loadTiposClientes();
      })
      .catch(() => setErrorMessage('Erro ao deletar tipo de cliente.'));
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Tipo de Cliente
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Tipo</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tipos.map(tipo => (
            <TableRow key={tipo.id}>
              <TableCell>{tipo.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', tipo)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteTipoCliente(tipo.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Tipo de Cliente' : 'Editar Tipo de Cliente'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Tipo"
            fullWidth
            value={tipoForm.nome}
            onChange={(e) => setTipoForm({ ...tipoForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default TipoCliente;
