import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, Paper, Alert } from '@mui/material';
import logo from './bg_logo.png';

const RedefinirSenha = () => {
  const { token } = useParams();
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (novaSenha !== confirmarSenha) {
      setError('As senhas não coincidem.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/recuperacao/redefinir-senha/${token}`, { novaSenha });

      if (response.data) {
        setSuccess('Senha alterada com sucesso. Redirecionando para o login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      setError('Erro ao redefinir a senha. Tente novamente.');
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {/* Painel Esquerdo */}
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        sx={{
          backgroundColor: '#0D1B2A',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Typography component="h2" variant="h4" gutterBottom align="center">
          CONTTALK
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 3 }}>
          Redefina sua senha e volte ao sistema com segurança!
        </Typography>
      </Grid>

      {/* Painel Direito */}
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="img" src={logo} alt="Logo" sx={{ width: '50%', mb: 2 }} />

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%', maxWidth: 400 }}>
            <Typography variant="h5" gutterBottom>Redefinir Senha</Typography>

            <TextField
              label="Nova Senha"
              type="password"
              fullWidth
              value={novaSenha}
              onChange={(e) => setNovaSenha(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Confirmar Nova Senha"
              type="password"
              fullWidth
              value={confirmarSenha}
              onChange={(e) => setConfirmarSenha(e.target.value)}
              sx={{ mb: 2 }}
            />

            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ backgroundColor: '#F0B429', '&:hover': { backgroundColor: '#C88F11' } }}
            >
              Redefinir Senha
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RedefinirSenha;
