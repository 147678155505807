import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../components/bg_logo.png'; // Caminho para o logo do sistema

const NotFound = () => {
  const navigate = useNavigate();

  // Função para voltar à página anterior
  const handleBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 2,
      }}
    >
      {/* Logo centralizado */}
      <Box
        component="img"
        src={logo}
        alt="Logo do Sistema"
        sx={{ height: 100, mb: 2 }}
      />
      
      {/* Mensagem de erro */}
      <Typography variant="h4" color="error" sx={{ mb: 1 }}>
        Página não encontrada
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
        A página que você está tentando acessar não existe.
      </Typography>

      {/* Botão para voltar */}
      <Button variant="contained" color="primary" onClick={handleBack}>
        Voltar
      </Button>
    </Box>
  );
};

export default NotFound;
