import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const CategoriaCliente = () => {
  const [categorias, setCategorias] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [categoriaForm, setCategoriaForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar categorias de clientes com token JWT
  useEffect(() => {
    loadCategoriasClientes();
  }, []);

  const loadCategoriasClientes = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/categorias-clientes`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setCategorias(response.data))
      .catch((error) => {
        console.error('Erro ao carregar categorias de clientes:', error);
        setErrorMessage('Erro ao carregar categorias de clientes.');
      });
  };

  // Função para abrir o modal
  const handleOpenModal = (type, categoria = null) => {
    setModalType(type);
    if (categoria) {
      setSelectedCategoria(categoria);
      setCategoriaForm({ nome: categoria.nome });
    } else {
      setSelectedCategoria(null);
      setCategoriaForm({ nome: '' });
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCategoria(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar categoria de cliente
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!categoriaForm.nome) {
      setErrorMessage('O nome da categoria é obrigatório.');
      return;
    }

    const formData = { nome: categoriaForm.nome };

    if (modalType === 'edit' && selectedCategoria) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/categorias-clientes/${selectedCategoria.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Categoria de cliente atualizada com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadCategoriasClientes();
        })
        .catch((error) => {
          console.error('Erro ao atualizar categoria de cliente:', error);
          setErrorMessage('Erro ao atualizar categoria de cliente.');
        });
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/categorias-clientes`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Categoria de cliente criada com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadCategoriasClientes();
        })
        .catch((error) => {
          console.error('Erro ao criar categoria de cliente:', error);
          setErrorMessage('Erro ao criar categoria de cliente.');
        });
    }
  };

  // Função para deletar categoria de cliente
  const handleDeleteCategoriaCliente = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/categorias-clientes/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Categoria de cliente deletada com sucesso.');
        setShowSnackbar(true);
        loadCategoriasClientes();
      })
      .catch((error) => {
        console.error('Erro ao deletar categoria de cliente:', error);
        setErrorMessage('Erro ao deletar categoria de cliente.');
      });
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Categoria de Cliente
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome da Categoria</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categorias.map(categoria => (
            <TableRow key={categoria.id}>
              <TableCell>{categoria.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', categoria)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteCategoriaCliente(categoria.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Categoria de Cliente' : 'Editar Categoria de Cliente'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome da Categoria"
            fullWidth
            value={categoriaForm.nome}
            onChange={(e) => setCategoriaForm({ ...categoriaForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default CategoriaCliente;
