import React, { useState } from 'react';
import {
  Button, TextField, Box, Typography, Grid, Paper, Alert, Modal, Backdrop, Fade, IconButton, CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios para fazer a requisição ao backend
import CloseIcon from '@mui/icons-material/Close';
import logo from './bg_logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Indicador de carregamento
  const [isModalOpen, setIsModalOpen] = useState(false); // Controle para o modal de recuperação de senha
  const [recoveryEmail, setRecoveryEmail] = useState(''); // Email para recuperação de senha
  const [recoveryError, setRecoveryError] = useState(''); // Controle de erro para recuperação
  const [recoverySuccess, setRecoverySuccess] = useState(''); // Confirmação de sucesso
  const navigate = useNavigate();

  // Função de login com backend
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Limpa mensagens de erro anteriores
    setIsLoading(true); // Ativa o indicador de carregamento

    if (!email || !senha) {
      setError('Informe seu e-mail e senha.');
      setIsLoading(false); // Desativa o indicador de carregamento
      return;
    }

    try {
      // Envia os dados de login para o backend
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, senha });

      // Se o login for bem-sucedido
      if (response.data && response.data.token) {
        const { token, usuario } = response.data;

        localStorage.setItem('token', token); // Salva o token no localStorage
        localStorage.setItem('usuario', JSON.stringify(usuario)); // Salva os dados do usuário

        // Redireciona para o dashboard
        navigate('/dashboard');
      } else {
        setError('Credenciais incorretas. Tente novamente.');
      }
    } catch (error) {
      // Verifique o status do erro para diferenciar credenciais inválidas de erros de conexão
      if (error.response && error.response.status === 400) {
        setError('Credenciais incorretas. Tente novamente.');
      } else if (error.response && error.response.status === 404) {
        setError('Usuário não encontrado.');
      } else {
        setError('Erro de conexão com o servidor. Tente novamente mais tarde.');
      }
    } finally {
      setIsLoading(false); // Desativa o indicador de carregamento
    }
  };

  // Função para abrir o modal de recuperação de senha
  const handleForgotPassword = () => {
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRecoveryEmail('');
    setRecoveryError('');
    setRecoverySuccess('');
  };

  // Função para envio do e-mail de recuperação de senha
  const handleSendRecoveryEmail = async () => {
    if (!recoveryEmail) {
      setRecoveryError('Informe o e-mail para recuperação.');
      return;
    }

    try {
      // Envia o e-mail de recuperação para o backend na URL /api/recuperacao
      await axios.post(`${process.env.REACT_APP_API_URL}/api/recuperacao`, { email: recoveryEmail });
      setRecoverySuccess(`Instruções de recuperação enviadas para: ${recoveryEmail}`);
      setRecoveryError(''); // Limpa o erro anterior
    } catch (error) {
      setRecoveryError('Erro ao enviar e-mail de recuperação. Tente novamente.');
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {/* Painel Esquerdo */}
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        sx={{
          backgroundColor: '#0D1B2A',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Typography component="h2" variant="h4" gutterBottom align="center">
          PROGRAMA DE INDICAÇÃO CONTTALK
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 3 }}>
          Indique amigos e seja bonificado com <strong>+20 CNPJs</strong> em seu pacote!
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#F0B429',
            '&:hover': {
              backgroundColor: '#C88F11',
            },
            px: 4,
            py: 1.5,
            fontSize: 16,
          }}
        >
          INDICAR AMIGOS
        </Button>
      </Grid>

      {/* Painel Direito */}
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="img" src={logo} alt="Logo" sx={{ width: '50%', mb: 2 }} />

          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: '100%', maxWidth: 400 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              error={!!error}
            />

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, backgroundColor: '#F0B429', '&:hover': { backgroundColor: '#C88F11' } }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'ENTRAR'}
            </Button>

            <Button
              fullWidth
              color="secondary"
              onClick={handleForgotPassword}
              sx={{
                mt: 1,
                color: '#8D99AE',
                textTransform: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Esqueceu sua senha? Clique aqui
            </Button>
          </Box>
        </Box>
      </Grid>

      {/* Modal de recuperação de senha */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Recuperação de Senha
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="recovery-email"
              label="Informe seu e-mail"
              name="recovery-email"
              autoComplete="email"
              value={recoveryEmail}
              onChange={(e) => setRecoveryEmail(e.target.value)}
              error={!!recoveryError}
              helperText={recoveryError}
            />
            {recoverySuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {recoverySuccess}
              </Alert>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSendRecoveryEmail}
              sx={{ mt: 2, backgroundColor: '#F0B429', '&:hover': { backgroundColor: '#C88F11' } }}
            >
              Enviar Instruções
            </Button>
            <Button
              fullWidth
              onClick={handleCloseModal}
              sx={{ mt: 1, color: '#8D99AE', textTransform: 'none', '&:hover': { textDecoration: 'underline' } }}
            >
              Cancelar
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default Login;
