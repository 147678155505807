import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const Departamento = () => {
  const [departamentos, setDepartamentos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);
  const [departamentoForm, setDepartamentoForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar departamentos com token JWT
  useEffect(() => {
    loadDepartamentos();
  }, []);

  const loadDepartamentos = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/departamento`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setDepartamentos(response.data))
      .catch(() => setErrorMessage('Erro ao carregar departamentos.'));
  };

  // Função para abrir o modal
  const handleOpenModal = (type, departamento = null) => {
    setModalType(type);
    if (departamento) {
      setSelectedDepartamento(departamento);
      setDepartamentoForm({ nome: departamento.nome });
    } else {
      setSelectedDepartamento(null);
      setDepartamentoForm({ nome: '' });
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDepartamento(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar departamento
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!departamentoForm.nome) {
      setErrorMessage('O nome do departamento é obrigatório.');
      return;
    }

    const formData = { nome: departamentoForm.nome };

    if (modalType === 'edit' && selectedDepartamento) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/departamento/${selectedDepartamento.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Departamento atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadDepartamentos();
        })
        .catch(() => setErrorMessage('Erro ao atualizar departamento.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/departamento`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Departamento criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadDepartamentos();
        })
        .catch(() => setErrorMessage('Erro ao criar departamento.'));
    }
  };

  // Função para deletar departamento
  const handleDeleteDepartamento = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/departamento/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Departamento deletado com sucesso.');
        setShowSnackbar(true);
        loadDepartamentos();
      })
      .catch(() => setErrorMessage('Erro ao deletar departamento.'));
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Departamento
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Departamento</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {departamentos.map(departamento => (
            <TableRow key={departamento.id}>
              <TableCell>{departamento.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', departamento)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteDepartamento(departamento.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Departamento' : 'Editar Departamento'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Departamento"
            fullWidth
            value={departamentoForm.nome}
            onChange={(e) => setDepartamentoForm({ ...departamentoForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default Departamento;
