import React, { useState, useEffect  } from 'react';
import {
  Box, Tabs, Tab, TextField, Button, Chip, Typography, Grid, MenuItem, Select, IconButton, Dialog, DialogActions, DialogContent, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, DialogContentText, Paper, DialogTitle
} from '@mui/material';
import { Person, Info, Contacts, Search, Note, Share, People, ListAlt, Add } from '@mui/icons-material';
import axios from 'axios'; // Importar Axios
import InputMask from 'react-input-mask'; // Importação da máscara
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';


// Defina a URL base da API
const API_BASE_URL = process.env.REACT_APP_API_URL; // sem '/api' no final

const NovoCliente = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    nome: '',
    nomeFantasia: '',
    apelido: '',
    cpfCnpj: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    cei: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    uf: '',  // Inicializa como string vazia
    cidade: '',
    website: '',
    honorario: ''

    
  });

   // Definindo o estado de contatos
   const [contatos, setContatos] = useState([
    { nome: '', cargo: '', celular: '', email: '', tipoEmail: '', anotacoes: '' }
  ]);

  const handleAddContato = () => {
    setContatos([...contatos, { nome: '', cargo: '', celular: '', email: '', tipoEmail: '', anotacoes: '' }]);
  };

  const handleRemoveContato = (index) => {
    setContatos(contatos.filter((_, i) => i !== index));
  };
  const [openModal, setOpenModal] = useState(false); // Estado para controlar a abertura do modal
  const [cnpjTimeout, setCnpjTimeout] = useState(null); // Timeout para CNPJ
  const estadosBrasileiros = [
    { sigla: 'AC', nome: 'Acre' },
    { sigla: 'AL', nome: 'Alagoas' },
    { sigla: 'AP', nome: 'Amapá' },
    { sigla: 'AM', nome: 'Amazonas' },
    { sigla: 'BA', nome: 'Bahia' },
    { sigla: 'CE', nome: 'Ceará' },
    { sigla: 'DF', nome: 'Distrito Federal' },
    { sigla: 'ES', nome: 'Espírito Santo' },
    { sigla: 'GO', nome: 'Goiás' },
    { sigla: 'MA', nome: 'Maranhão' },
    { sigla: 'MT', nome: 'Mato Grosso' },
    { sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { sigla: 'MG', nome: 'Minas Gerais' },
    { sigla: 'PA', nome: 'Pará' },
    { sigla: 'PB', nome: 'Paraíba' },
    { sigla: 'PR', nome: 'Paraná' },
    { sigla: 'PE', nome: 'Pernambuco' },
    { sigla: 'PI', nome: 'Piauí' },
    { sigla: 'RJ', nome: 'Rio de Janeiro' },
    { sigla: 'RN', nome: 'Rio Grande do Norte' },
    { sigla: 'RS', nome: 'Rio Grande do Sul' },
    { sigla: 'RO', nome: 'Rondônia' },
    { sigla: 'RR', nome: 'Roraima' },
    { sigla: 'SC', nome: 'Santa Catarina' },
    { sigla: 'SP', nome: 'São Paulo' },
    { sigla: 'SE', nome: 'Sergipe' },
    { sigla: 'TO', nome: 'Tocantins' },
  ];


  const [tags, setTags] = useState([]); // Estado para tags
  const [tagInput, setTagInput] = useState(''); // Estado para input de tags

   // Função para adicionar tag ao pressionar Enter
   const handleKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim() !== '') {
      e.preventDefault();
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  // Função para remover uma tag
  const handleDeleteTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };
// Função para adicionar uma nova rede social
const handleAddRedeSocial = () => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    redesSociais: [...(prevFormData.redesSociais || []), { nome: '', url: '' }]
  }));
};

// Função para alterar os valores das redes sociais
const handleRedeSocialChange = (index, field, value) => {
  const novasRedes = [...formData.redesSociais];
  novasRedes[index][field] = value;
  setFormData({ ...formData, redesSociais: novasRedes });
};

// Função para remover uma rede social
const handleRemoveRedeSocial = (index) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    redesSociais: prevFormData.redesSociais.filter((_, i) => i !== index)
  }));
};

  

  const [opcoesStatus, setOpcoesStatus] = useState({
    tipoCliente: [],
    statusCliente: [],
    segmento: [],
    grupoCliente: [],
    statusComplementar: [],
    escritorio: [],
    categoriaCliente: []
  });
  const [campoSelecionado, setCampoSelecionado] = useState(''); // Guarda o campo a ser adicionado
  const [novoCampo, setNovoCampo] = useState(''); // Guarda o valor do novo campo
  


  

  const handleContatoChange = (index, field, value) => {
    const newContatos = [...contatos];
    newContatos[index][field] = value;
    setContatos(newContatos);
  };
  useEffect(() => {
    if (activeTab === 1) {
      fetchStatusData(); // Chama a função apenas quando a aba Status for ativada
    }
  }, [activeTab]);

  
  // useEffect para buscar o CNPJ quando o campo tiver exatamente 14 dígitos
 useEffect(() => {
    if (formData.cpfCnpj.length === 14) {
      fetchCnpjData(formData.cpfCnpj);  // Faz a requisição quando o CNPJ está completo
    }
  }, [formData.cpfCnpj]);  // O useEffect vai monitorar mudanças no campo cpfCnpj
  
  // Função para lidar com mudanças no campo de texto
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Verifica se o campo é "tipoCliente" ou "segmento" para permitir múltipla escolha
    if (name === 'tipoCliente' || name === 'segmento') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: typeof value === 'string' ? value.split(',') : value, // Garante array
      }));
    } else {
      // Comportamento padrão para outros campos
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  

  const fetchStatusData = async () => {
    try {
      const token = localStorage.getItem('token'); // Obter o token JWT
  
       // Fazer múltiplas requisições simultaneamente usando Promise.all
    const [
      tipoCliente, statusCliente, segmento, grupoCliente,
      statusComplementar, escritorio, categoriaCliente
    ] = await Promise.all([
      axios.get(`${API_BASE_URL}/api/tipo-cliente`, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(`${API_BASE_URL}/api/status-clientes`, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(`${API_BASE_URL}/api/segmento-empresa`, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(`${API_BASE_URL}/api/grupo-clientes`, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(`${API_BASE_URL}/api/status-complementares`, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(`${API_BASE_URL}/api/escritorios`, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(`${API_BASE_URL}/api/categorias-clientes`, { headers: { Authorization: `Bearer ${token}` } }),
    ]);
      
  
      // Atualizar o estado com os dados recebidos
      setOpcoesStatus({
        tipoCliente: tipoCliente.data,
        statusCliente: statusCliente.data,
        segmento: segmento.data,
        grupoCliente: grupoCliente.data,
        statusComplementar: statusComplementar.data,
        escritorio: escritorio.data,
        categoriaCliente: categoriaCliente.data
      });
    } catch (error) {
      console.error('Erro ao buscar dados de status:', error);
    }
  };
  
  // Função para formatar o valor em moeda brasileira
const formatCurrency = (value) => {
  const numberValue = Number(value) / 100; // Divide por 100 para ajustar casas decimais
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(numberValue || 0);
};
  
const [openNovaVigencia, setOpenNovaVigencia] = useState(false);

  // Lida com a formatação de moeda em tempo real para o campo "Honorário"
  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatCurrency(value);
    setFormData({
      ...formData,
      honorario: formattedValue  // Atualiza o valor do honorário já formatado
    });
  };

  // Função para buscar o CNPJ usando JSONP
  const fetchCnpjData = (cnpj) => {
    const script = document.createElement('script');
    const callbackName = `jsonp_callback_${Date.now()}`;

    // Define a função de callback globalmente
    window[callbackName] = (data) => {
      if (data.status === 'ERROR') {
        alert('CNPJ não encontrado ou inválido!');
        return;
      }

      // Atualiza os campos do formulário com os dados retornados
      setFormData({
        ...formData,
        nomeFantasia: data.fantasia || '',
        nome: data.nome || '',
        logradouro: data.logradouro || '',
        numero: data.numero || '',
        complemento: data.complemento || '',
        bairro: data.bairro || '',
        cep: data.cep ? data.cep.replace(/\D/g, '') : '',
        cidade: data.municipio || '',
        uf: data.uf || '',  // Atualiza o estado automaticamente
        website: data.site || '',
        inscricaoEstadual: data.inscricao_estadual || ''
      });

      // Remove o script e o callback global após o uso
      document.body.removeChild(script);
      delete window[callbackName];
    };

    // Cria o script com a URL da API e o parâmetro `callback`
    script.src = `https://www.receitaws.com.br/v1/cnpj/${cnpj}?callback=${callbackName}`;
    script.async = true;

    // Adiciona o script ao DOM para executar a requisição JSONP
    document.body.appendChild(script);
  };

  // Muda a aba ativa
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = () => {
    console.log('Form data:', formData);
  };

  // Controla a abertura e fechamento do modal
  const handleOpenModal = (campo) => {
    setCampoSelecionado(campo);
    setOpenModal(true); // Abre o modal
    setNovoCampo(''); // Limpa o campo anterior
    setEscritorioForm({ nome: '', endereco: '', telefone: '', numero_do_escritorio: '' }); // Reset para o formulário de escritórios
  };
  
  const [escritorioForm, setEscritorioForm] = useState({
    nome: '',
    endereco: '',
    telefone: '',
    numero_do_escritorio: '',
  });
  
  const handleAddResponsavel = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      responsaveis: [...(prevFormData.responsaveis || []), { nome: '', tipo: '' }]
    }));
  };
  
  const handleResponsavelChange = (index, field, value) => {
    const novosResponsaveis = [...formData.responsaveis];
    novosResponsaveis[index][field] = value;
    setFormData({ ...formData, responsaveis: novosResponsaveis });
  };
  
  const handleRemoveResponsavel = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      responsaveis: prevFormData.responsaveis.filter((_, i) => i !== index)
    }));
  };
  
  
// Função para adicionar uma nova inscrição
const handleAddInscricao = () => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    inscricoes: [...(prevFormData.inscricoes || []), { tipo: '', numero: '' }]
  }));
};

// Função para alterar os valores das inscrições
const handleInscricaoChange = (index, field, value) => {
  const novasInscricoes = [...formData.inscricoes];
  novasInscricoes[index][field] = value;
  setFormData({ ...formData, inscricoes: novasInscricoes });
};

// Função para remover uma inscrição
const handleRemoveInscricao = (index) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    inscricoes: prevFormData.inscricoes.filter((_, i) => i !== index)
  }));
};


const handleSelectChange = (field, value) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    [field]: Array.isArray(value) ? value : [value], // Garante que múltiplos valores sejam sempre arrays
  }));
};



  
  const handleAddCampo = async () => {
    try {
      const token = localStorage.getItem('token');
  
      if (campoSelecionado === 'escritorio' && !escritorioForm.nome) {
        console.error('O nome do escritório é obrigatório.');
        return;
      } else if (!novoCampo && campoSelecionado !== 'escritorio') {
        console.error('O nome do campo é obrigatório.');
        return;
      }
  
      // Definir o endpoint e os dados corretamente
      let endpoint = '';
      let formData = { nome: novoCampo }; // Dados padrão
  
      switch (campoSelecionado) {
        case 'tipoCliente':
          endpoint = 'tipo-cliente';
          break;
        case 'statusCliente':
          endpoint = 'status-clientes';
          break;
        case 'segmento':
          endpoint = 'segmento-empresa';
          break;
        case 'grupoCliente':
          endpoint = 'grupo-clientes';
          break;
        case 'statusComplementar':
          endpoint = 'status-complementares';
          break;
        case 'escritorio':
          endpoint = 'escritorios';
          formData = { 
            ...escritorioForm, 
            empresa_id: JSON.parse(localStorage.getItem('empresa_id')) 
          };
          break;
        case 'categoriaCliente':
          endpoint = 'categorias-clientes';
          break;
        default:
          console.error('Campo selecionado inválido.');
          return;
      }
  
      const url = `${API_BASE_URL}/api/${endpoint}`;
      console.log('Enviando para a URL:', url);
      console.log('Dados enviados:', formData);
  
      const response = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log('Campo adicionado com sucesso:', response.data);
  
      fetchStatusData();
      setOpenModal(false);
      setNovoCampo('');
      setEscritorioForm({ nome: '', endereco: '', telefone: '', numero_do_escritorio: '' });
    } catch (error) {
      console.error('Erro ao adicionar novo campo:', error);
      console.error('Detalhes do erro:', error.response?.data || error.message);
    }
  };
  
  
  
  

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Grid container spacing={2}>
        {/* Coluna das Abas */}
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              backgroundColor: '#f5f5f5', // Fundo cinza claro
              borderRadius: '8px', // Cantos arredondados
              boxShadow: '0px 4px 12px rgba(0, 0, 10, 0.1)', // Efeito de sombra
              '& .MuiTab-root': {
                textTransform: 'none', // Manter o texto normal
                justifyContent: 'flex-start', // Alinhar texto à esquerda
                alignItems: 'center', // Centralizar conteúdo verticalmente
                padding: '12px 24px', // Espaçamento interno das abas
                '&:hover': {
                  backgroundColor: '#e0e0e0', // Mudança de cor ao passar o mouse
                },
              },
              '& .Mui-selected': {
                backgroundColor: '#f5f5f5', // Cor de fundo da aba ativa
                color: '#8c52ff', // Texto branco na aba ativa
                fontWeight: 'bold', // Negrito na aba ativa
              }
            }}
          >
            <Tab icon={<Person />} iconPosition="start" label="Dados Básicos" />
            <Tab icon={<Info />} iconPosition="start" label="Classificação" />
            <Tab icon={<Info />} iconPosition="start" label="Inscrições" />
            <Tab icon={<Contacts />} iconPosition="start" label="Contatos" />
            <Tab icon={<Search />} iconPosition="start" label="Campos de pesquisa" />
            <Tab icon={<Note />} iconPosition="start" label="Anotações" />
            <Tab icon={<Share />} iconPosition="start" label="Rede Social" />
            <Tab icon={<People />} iconPosition="start" label="Responsável" />
            <Tab icon={<ListAlt />} iconPosition="start" label="Lista de atividades" />
          </Tabs>
        </Grid>

        {/* Conteúdo das Abas */}
        <Grid item xs={9}>
          {/* Dados Básicos */}
          {activeTab === 0 && (
            <Box sx={{ padding: 3 }}>
              <Typography variant="h6" gutterBottom>Dados Básicos</Typography>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Código ERP"
                  name="erp" 
                  value={formData.erp || ''} 
                  onChange={handleChange} 
                  placeholder="Digite o código ERP"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CPF/CNPJ"
                    name="cpfCnpj"
                    value={formData.cpfCnpj}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome Fantasia"
                    name="nomeFantasia"
                    value={formData.nomeFantasia}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Apelido"
                    name="apelido"
                    value={formData.apelido}
                    onChange={handleChange}
                  />
                </Grid>
              
                             

                {/* Campo de CEP que dispara a busca do endereço */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={formData.cep}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Campos de endereço que serão preenchidos automaticamente */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Logradouro"
                    name="logradouro"
                    value={formData.logradouro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="UF"
                    name="uf"
                    value={formData.uf}
                    onChange={handleChange}
                    select
                >
                    {estadosBrasileiros.map((estado) => (
                    <MenuItem key={estado.sigla} value={estado.sigla}>
                        {estado.nome} ({estado.sigla})
                    </MenuItem>
                    ))}
                </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Honorário"
                    name="honorario"
                    value={formData.honorario}
                    onChange={handleCurrencyChange}  // Função especial para formatar moeda
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Status */}
          {activeTab === 1 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Classificação
    </Typography>

    <Grid container spacing={3}>
      {[
        { label: 'Status Cliente', field: 'statusCliente' },
        { label: 'Status Complementar', field: 'statusComplementar' },
        { label: 'Tipo do Cliente', field: 'tipoCliente', multiple: true },
        { label: 'Categoria Cliente', field: 'categoriaCliente' },
        { label: 'Grupo Cliente', field: 'grupoCliente' },
        { label: 'Segmento', field: 'segmento', multiple: true },
        { label: 'Escritório', field: 'escritorio' },
      ].map(({ label, field, multiple }) => (
        <Grid item xs={12} sm={6} key={field} sx={{ marginBottom: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
            <Typography variant="body1">{label}</Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setCampoSelecionado(field);
                setOpenModal(true);
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          </Box>

          <Select
            fullWidth
            multiple={multiple} // Define se o campo permite múltipla escolha
            value={formData[field] || (multiple ? [] : '')}
            onChange={(e) =>
              handleSelectChange(field, multiple ? e.target.value : [e.target.value])
            }
            renderValue={(selected) => {
              if (!multiple) {
                // Seleção única
                const option = opcoesStatus[field]?.find((item) => item.id === selected[0]);
                return option ? option.nome : <em>Selecione {label}</em>;
              }
              // Múltipla seleção
              return selected
                .map((id) => {
                  const option = opcoesStatus[field]?.find((item) => item.id === id);
                  return option ? option.nome : id;
                })
                .join(', ');
            }}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecione {label}</em>
            </MenuItem>
            {opcoesStatus[field]?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      ))}
    </Grid>
  </Box>
)}


{activeTab === 2 && (
  <Box sx={{ padding: 3 }}>
   {/* Verifique se este console é exibido */}
   {console.log('Renderizando a aba de Contatos')} 

<Typography 
  variant="h6" 
  gutterBottom 
  sx={{ color: 'black', marginBottom: 5 }} // Estilização adicional para garantir visibilidade
>
  Inscrições
</Typography>

    <Grid container spacing={2}>
      {formData.inscricoes?.map((inscricao, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            padding: 2,
            borderRadius: 2,
          }}
        >
          {/* Select para o Tipo de Inscrição */}
          <Grid item xs={12} sm={3}>
            <Select
              value={inscricao.tipo || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'tipo', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione o Tipo de Inscrição</em>
              </MenuItem>
              <MenuItem value="Inscrição Estadual">Inscrição Estadual</MenuItem>
              <MenuItem value="Inscrição Municipal">Inscrição Municipal</MenuItem>
              <MenuItem value="CEI">CEI</MenuItem>
              <MenuItem value="CAEPF">CAEPF</MenuItem>
              <MenuItem value="CNO">CNO</MenuItem>
              <MenuItem value="CCIR">CCIR</MenuItem>
              <MenuItem value="NIRF">NIRF</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </Select>
          </Grid>

          {/* Campo para Número da Inscrição */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Número da Inscrição"
              value={inscricao.numero || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'numero', e.target.value)
              }
              placeholder="Digite o número da inscrição"
              fullWidth
            />
          </Grid>

          {/* Campo para Identificação */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Identificação"
              value={inscricao.identificacao || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'identificacao', e.target.value)
              }
              placeholder="Digite a identificação"
              fullWidth
            />
          </Grid>

          {/* Select para Status */}
          <Grid item xs={12} sm={2}>
            <Select
              value={inscricao.status || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'status', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione o Status</em>
              </MenuItem>
              <MenuItem value="ATIVO">ATIVO</MenuItem>
              <MenuItem value="BAIXADO">BAIXADO</MenuItem>
              <MenuItem value="SUSPENSO">SUSPENSO</MenuItem>
              <MenuItem value="INATIVO">INATIVO</MenuItem>
            </Select>
          </Grid>

          {/* Botão para Remover Inscrição */}
          <Grid item xs={12} sm={1} sx={{ textAlign: 'center' }}>
            <IconButton
              color="error"
              onClick={() => handleRemoveInscricao(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {/* Botão para Adicionar Nova Inscrição */}
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddInscricao}
        >
          Adicionar Inscrição
        </Button>
      </Grid>
    </Grid>
  </Box>
)}


           {/* Contacts */}
           {activeTab === 3 && (
  <Box sx={{ padding: 3 }}>
    {/* Verifique se este console é exibido */}
    {console.log('Renderizando a aba de Contatos')} 

    <Typography 
      variant="h6" 
      gutterBottom 
      sx={{ color: 'black', marginBottom: 5 }} // Estilização adicional para garantir visibilidade
    >
      Contatos
    </Typography>

    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      {contatos.map((contato, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Grid item xs={12} sm={3}>
            <TextField
              label="Nome"
              fullWidth
              value={contato.nome}
              onChange={(e) => handleContatoChange(index, 'nome', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Cargo"
              fullWidth
              value={contato.cargo}
              onChange={(e) => handleContatoChange(index, 'cargo', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Celular"
              fullWidth
              value={contato.celular}
              onChange={(e) => handleContatoChange(index, 'celular', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="E-mail"
              fullWidth
              value={contato.email}
              onChange={(e) => handleContatoChange(index, 'email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              value={contato.tipoEmail}
              onChange={(e) => handleContatoChange(index, 'tipoEmail', e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Tipo de E-mail</em>
              </MenuItem>
              <MenuItem value="Contabil">Contábil</MenuItem>
              <MenuItem value="Compras">Compras</MenuItem>
              <MenuItem value="Financeiro">Financeiro</MenuItem>
              <MenuItem value="Fiscal">Fiscal</MenuItem>
              <MenuItem value="Legalizacao">Legalização</MenuItem>
              <MenuItem value="Licitacao">Licitação</MenuItem>
              <MenuItem value="Pessoal">Pessoal</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Anotações"
              fullWidth
              multiline
              rows={2}
              value={contato.anotacoes}
              onChange={(e) => handleContatoChange(index, 'anotacoes', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton color="error" onClick={() => handleRemoveContato(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddContato}
          sx={{ marginTop: 2 }}
        >
          Adicionar Contato
        </Button>
      </Grid>
    </Grid>
  </Box>
         
          )}

{activeTab === 4 && (
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            Campos de pesquisa
          </Typography>
          <TextField
            label="Digite o nome"
            fullWidth
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Pressione Enter para adicionar"
            sx={{ marginBottom: 2 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(index)}
                color="primary"
              />
            ))}
          </Box>
        </Box>
      )}

{activeTab === 5 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Anotações
    </Typography>

    <Grid container spacing={2}>
      {/* Campo Data e Hora (somente leitura) */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Data"
          value={new Date().toLocaleDateString('pt-BR')}
          InputProps={{ readOnly: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Hora"
          value={new Date().toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
          InputProps={{ readOnly: true }}
          fullWidth
        />
      </Grid>

      {/* Nome do Usuário */}
      <Grid item xs={12}>
        <TextField
          label="Usuário"
          value={localStorage.getItem('usuario') || 'Usuário não identificado'}
          InputProps={{ readOnly: true }}
          fullWidth
        />
      </Grid>

      {/* Campo de Descrição */}
      <Grid item xs={12}>
        <TextField
          label="Descrição"
          multiline
          rows={4}
          placeholder="Escreva sua anotação aqui..."
          fullWidth
        />
      </Grid>

      {/* Botão para Anexar Arquivo */}
      <Grid item xs={12}>
        <Button variant="outlined" component="label">
          Anexar Arquivo
          <input type="file" hidden multiple />
        </Button>
      </Grid>

     
    </Grid>
  </Box>
)}


{activeTab === 6 && (
  <Box sx={{ padding: 3 }}>
     {/* Verifique se este console é exibido */}
     {console.log('Renderizando a aba de Contatos')} 

<Typography 
  variant="h6" 
  gutterBottom 
  sx={{ color: 'black', marginBottom: 5 }} // Estilização adicional para garantir visibilidade
>
  Rede Social
</Typography>

    <Grid container spacing={2}>
      {formData.redesSociais?.map((rede, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            padding: 2,
            borderRadius: 2,
          }}
        >
          {/* Seletor de Rede Social */}
          <Grid item xs={12} sm={5}>
            <Select
              value={rede.nome || ''}
              onChange={(e) =>
                handleRedeSocialChange(index, 'nome', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione a Rede Social</em>
              </MenuItem>
              <MenuItem value="Facebook">Facebook</MenuItem>
              <MenuItem value="Instagram">Instagram</MenuItem>
              <MenuItem value="LinkedIn">LinkedIn</MenuItem>
              <MenuItem value="Twitter">Twitter</MenuItem>
              <MenuItem value="YouTube">YouTube</MenuItem>
              <MenuItem value="TikTok">TikTok</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </Select>
          </Grid>

          {/* Campo de URL */}
          <Grid item xs={12} sm={5}>
            <TextField
              label="URL da Rede Social"
              value={rede.url || ''}
              onChange={(e) =>
                handleRedeSocialChange(index, 'url', e.target.value)
              }
              placeholder="Digite a URL completa (ex: https://...)"
              fullWidth
            />
          </Grid>

          {/* Botão para Remover Rede Social */}
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <IconButton
              color="error"
              onClick={() => handleRemoveRedeSocial(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {/* Botão para Adicionar Nova Rede Social */}
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddRedeSocial}
        >
          Adicionar Rede Social
        </Button>
      </Grid>
    </Grid>
  </Box>
)}


{activeTab === 7 && (
  <Box sx={{ padding: 3 }}>
     {/* Verifique se este console é exibido */}
     {console.log('Renderizando a aba de Contatos')} 

<Typography 
  variant="h6" 
  gutterBottom 
  sx={{ color: 'black', marginBottom: 5 }} // Estilização adicional para garantir visibilidade
>
  Responsável
</Typography>

    <Grid container spacing={2}>
      {formData.responsaveis?.map((responsavel, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            padding: 2,
            borderRadius: 2,
          }}
        >
          {/* Select para o Nome do Responsável */}
          <Grid item xs={12} sm={5}>
            <Select
              value={responsavel.nome || ''}
              onChange={(e) =>
                handleResponsavelChange(index, 'nome', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione o Nome do Responsável</em>
              </MenuItem>
              <MenuItem value="Responsável 1">Responsável 1</MenuItem>
              <MenuItem value="Responsável 2">Responsável 2</MenuItem>
              <MenuItem value="Responsável 3">Responsável 3</MenuItem>
              <MenuItem value="Responsável 4">Responsável 4</MenuItem>
            </Select>
          </Grid>

          {/* Select para o Tipo de Responsável */}
          <Grid item xs={12} sm={5}>
            <Select
              value={responsavel.tipo || ''}
              onChange={(e) =>
                handleResponsavelChange(index, 'tipo', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione o Tipo de Responsável</em>
              </MenuItem>
              <MenuItem value="Financeiro">Financeiro</MenuItem>
              <MenuItem value="Técnico">Técnico</MenuItem>
              <MenuItem value="Comercial">Comercial</MenuItem>
              <MenuItem value="Administrativo">Administrativo</MenuItem>
            </Select>
          </Grid>

          {/* Botão para Remover Responsável */}
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <IconButton
              color="error"
              onClick={() => handleRemoveResponsavel(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {/* Botão para Adicionar Novo Responsável */}
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddResponsavel}
        >
          Adicionar Responsável
        </Button>
      </Grid>
    </Grid>
  </Box>
)}

{activeTab === 8 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Lista de Atividades
    </Typography>

    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => setOpenNovaVigencia(true)}
      sx={{ marginBottom: 2 }}
    >
      Adicionar Nova Vigência
    </Button>

    {/* Tabela para mostrar vigências cadastradas */}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data do Cadastro</TableCell>
            <TableCell>Competência (Mês/Ano)</TableCell>
            <TableCell>Regime Tributário Federal</TableCell>
            <TableCell>Regime Tributário Estadual</TableCell>
            <TableCell>Regime Tributário Municipal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Aqui você pode iterar sobre as vigências cadastradas */}
          {/* Exemplo de uma linha fixa para exibição */}
          <TableRow>
            <TableCell>22/10/2024</TableCell>
            <TableCell>10/2024</TableCell>
            <TableCell>Simples Nacional</TableCell>
            <TableCell>Regime Normal</TableCell>
            <TableCell>ISS Fixo</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    {/* Modal para Adicionar Nova Vigência */}
    <Dialog open={openNovaVigencia} onClose={() => setOpenNovaVigencia(false)}>
      <DialogTitle>
        Nova Vigência - Cadastro
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Data do Cadastro (Editável) */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Data do Cadastro"
              type="date"
              defaultValue={new Date().toISOString().substring(0, 10)}
              fullWidth
            />
          </Grid>

          {/* Vigência referente à competência (Mês/Ano) */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Vigência (Competência Mês/Ano)"
              placeholder="mm/yyyy"
              fullWidth
              inputProps={{ maxLength: 7 }}
            />
          </Grid>

          {/* Regime Tributário Federal */}
          <Grid item xs={12}>
            <Select fullWidth displayEmpty>
              <MenuItem value="">
                <em>Selecione o Regime Tributário Federal</em>
              </MenuItem>
              <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
              <MenuItem value="Lucro Presumido">Lucro Presumido</MenuItem>
              <MenuItem value="Lucro Real">Lucro Real</MenuItem>
              <MenuItem value="Imunes e Isentas">Imunes e Isentas</MenuItem>
            </Select>
          </Grid>

          {/* Regime Tributário Estadual */}
          <Grid item xs={12}>
            <Select fullWidth displayEmpty>
              <MenuItem value="">
                <em>Selecione o Regime Tributário Estadual</em>
              </MenuItem>
              <MenuItem value="Regime Normal">Regime Normal</MenuItem>
              <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
              <MenuItem value="Substituição Tributária">Substituição Tributária</MenuItem>
              <MenuItem value="Regime de Estimativa">Regime de Estimativa</MenuItem>
            </Select>
          </Grid>

          {/* Regime Tributário Municipal */}
          <Grid item xs={12}>
            <Select fullWidth displayEmpty>
              <MenuItem value="">
                <em>Selecione o Regime Tributário Municipal</em>
              </MenuItem>
              <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
              <MenuItem value="ISS Fixo">ISS Fixo</MenuItem>
              <MenuItem value="ISS Variável">ISS Variável</MenuItem>
              <MenuItem value="Isento">Isento</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpenNovaVigencia(false)}>Cancelar</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenNovaVigencia(false)}
        >
          Salvar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setOpenNovaVigencia(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
)}







          
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Modal para adicionar novos campos */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
    <DialogTitle>
      {campoSelecionado === 'escritorio' ? 'Adicionar Escritório' : 'Adicionar'}
    </DialogTitle>
    <DialogContent>
      {campoSelecionado === 'escritorio' ? (
        <>
          <TextField
            label="Nome do Escritório"
            fullWidth
            value={escritorioForm.nome}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Endereço"
            fullWidth
            value={escritorioForm.endereco}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, endereco: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <InputMask
            mask="(99) 99999-9999"
            value={escritorioForm.telefone}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, telefone: e.target.value })}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                label="Telefone"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            )}
          </InputMask>
          <TextField
            label="Número do Escritório"
            type="number"
            fullWidth
            value={escritorioForm.numero_do_escritorio}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, numero_do_escritorio: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
        </>
      ) : (
        <TextField
          label="Nome"
          fullWidth
          value={novoCampo}
          onChange={(e) => setNovoCampo(e.target.value)}
        />
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
      <Button variant="contained" onClick={handleAddCampo}>
        Adicionar
      </Button>
    </DialogActions>
  </Dialog>



    </Box>
  );
};

export default NovoCliente;
