import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Card, CardContent, Typography, Chip, IconButton, Snackbar, Avatar, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow, Paper
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, HourglassEmpty as HourglassIcon, PlayArrow as PlayIcon, Pause as PauseIcon, Cancel as CancelIcon, ViewList as ViewListIcon, ViewModule as ViewModuleIcon, Close as CloseIcon } from '@mui/icons-material';

const tarefasExemplo = [
  {
    id: 1,
    titulo: 'Reunião com o cliente',
    descricao: 'Discutir sobre o projeto X e prazos de entrega.',
    status: 'pendente',
    usuario: { iniciales: 'AB' },
    detalhes: 'Discussão sobre os prazos e expectativas para o projeto com o cliente X.'
  },
  {
    id: 2,
    titulo: 'Revisar proposta de design',
    descricao: 'Analisar o layout final da homepage.',
    status: 'em_execucao',
    usuario: { iniciales: 'CD' },
    detalhes: 'A proposta precisa de ajustes nos componentes visuais.'
  },
  {
    id: 3,
    titulo: 'Atualizar relatório financeiro',
    descricao: 'Adicionar os dados do último mês.',
    status: 'concluida',
    usuario: { iniciales: 'EF' },
    detalhes: 'Todos os dados financeiros do mês foram inseridos corretamente.'
  },
  {
    id: 4,
    titulo: 'Revisão de código',
    descricao: 'Revisar código do módulo de autenticação.',
    status: 'pendente',
    usuario: { iniciales: 'GH' },
    detalhes: 'Verificar se todas as regras de segurança estão implementadas.'
  },
  {
    id: 5,
    titulo: 'Organizar workshop interno',
    descricao: 'Preparar material para o workshop de inovação.',
    status: 'cancelada',
    usuario: { iniciales: 'IJ' },
    detalhes: 'Workshop foi cancelado devido a baixa participação.'
  }
];

const PainelDeAtividades = () => {
  const [tarefas, setTarefas] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState('todas');
  const [tarefaSelecionada, setTarefaSelecionada] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [modoVisualizacao, setModoVisualizacao] = useState('cards'); // Alternância entre 'cards' e 'tabela'

  // Carregar tarefas de exemplo ao montar o componente
  useEffect(() => {
    setTarefas(tarefasExemplo);
  }, []);

  // Função para filtrar as tarefas por status
  const tarefasFiltradas = tarefas.filter(tarefa => filtroStatus === 'todas' || tarefa.status === filtroStatus);

  // Função para mudar o status da tarefa
  const handleStatusChange = (id, status) => {
    const novasTarefas = tarefas.map(tarefa =>
      tarefa.id === id ? { ...tarefa, status } : tarefa
    );
    setTarefas(novasTarefas);
    setSuccessMessage('Status da tarefa atualizado.');
    setShowSnackbar(true);
  };

  // Função para abrir o modal com detalhes da tarefa
  const handleOpenModal = (tarefa) => {
    setTarefaSelecionada(tarefa);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setTarefaSelecionada(null);
  };

  // Status visuais
  const statusChips = {
    'pendente': <Chip icon={<HourglassIcon />} label="Pendente" color="warning" />,
    'em_execucao': <Chip icon={<PlayIcon />} label="Em Execução" color="info" />,
    'concluida': <Chip icon={<CheckCircleIcon />} label="Concluída" color="success" />,
    'cancelada': <Chip icon={<CancelIcon />} label="Cancelada" color="error" />,
  };

  // Função para alternar entre modo de visualização (Cards ou Tabela)
  const alternarVisualizacao = () => {
    setModoVisualizacao(modoVisualizacao === 'cards' ? 'tabela' : 'cards');
  };

  return (
    <Box p={3}>
      {/* Título e Alternância de Visualização */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5" fontWeight="bold">
          Painel de Atividades
        </Typography>
        <IconButton onClick={alternarVisualizacao}>
          {modoVisualizacao === 'cards' ? <ViewListIcon /> : <ViewModuleIcon />}
        </IconButton>
      </Box>

      {/* Barra de Filtros */}
      <Box display="flex" justifyContent="flex-start" mb={3}>
        <Button
          variant={filtroStatus === 'todas' ? 'contained' : 'outlined'}
          onClick={() => setFiltroStatus('todas')}
          sx={{ marginRight: 1 }}
        >
          Todas
        </Button>
        <Button
          variant={filtroStatus === 'pendente' ? 'contained' : 'outlined'}
          onClick={() => setFiltroStatus('pendente')}
          sx={{ marginRight: 1 }}
        >
          Pendentes
        </Button>
        <Button
          variant={filtroStatus === 'em_execucao' ? 'contained' : 'outlined'}
          onClick={() => setFiltroStatus('em_execucao')}
          sx={{ marginRight: 1 }}
        >
          Em Execução
        </Button>
        <Button
          variant={filtroStatus === 'concluida' ? 'contained' : 'outlined'}
          onClick={() => setFiltroStatus('concluida')}
          sx={{ marginRight: 1 }}
        >
          Concluídas
        </Button>
        <Button
          variant={filtroStatus === 'cancelada' ? 'contained' : 'outlined'}
          onClick={() => setFiltroStatus('cancelada')}
        >
          Canceladas
        </Button>
      </Box>

      {/* Visualização em Cards ou Tabela */}
      {modoVisualizacao === 'cards' ? (
        <Grid container spacing={3}>
          {tarefasFiltradas.map((tarefa) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={tarefa.id}>
              <Card
                onClick={() => handleOpenModal(tarefa)}
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#fff',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)' },
                  cursor: 'pointer'
                }}
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>{tarefa.titulo}</Typography>
                    <Avatar sx={{ bgcolor: '#ffa500' }}>{tarefa.usuario?.iniciales}</Avatar>
                  </Box>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    {tarefa.descricao}
                  </Typography>

                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    {statusChips[tarefa.status]}
                    <Box>
                      {tarefa.status === 'em_execucao' ? (
                        <IconButton color="primary" onClick={() => handleStatusChange(tarefa.id, 'pendente')}>
                          <PauseIcon />
                        </IconButton>
                      ) : (
                        <IconButton color="primary" onClick={() => handleStatusChange(tarefa.id, 'em_execucao')}>
                          <PlayIcon />
                        </IconButton>
                      )}
                      <IconButton color="error" onClick={() => handleStatusChange(tarefa.id, 'cancelada')}>
                        <CancelIcon />
                      </IconButton>
                      <IconButton color="success" onClick={() => handleStatusChange(tarefa.id, 'concluida')}>
                        <CheckCircleIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Table component={Paper} sx={{ minWidth: 650, backgroundColor: '#fff', borderRadius: '8px', overflow: 'hidden' }}>
          <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Usuário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tarefasFiltradas.map((tarefa) => (
              <TableRow
                key={tarefa.id}
                onClick={() => handleOpenModal(tarefa)}
                sx={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                  '&:hover': { backgroundColor: '#fafafa' },
                }}
              >
                <TableCell>{tarefa.titulo}</TableCell>
                <TableCell>{tarefa.descricao}</TableCell>
                <TableCell>{statusChips[tarefa.status]}</TableCell>
                <TableCell>
                  <Avatar sx={{ bgcolor: '#ffa500' }}>{tarefa.usuario?.iniciales}</Avatar>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {/* Modal de Detalhes da Tarefa */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="tarefa-detalhes-titulo"
        aria-describedby="tarefa-detalhes-descricao"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px'
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography id="tarefa-detalhes-titulo" variant="h6">
              {tarefaSelecionada?.titulo}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography id="tarefa-detalhes-descricao" sx={{ mb: 2 }}>
            {tarefaSelecionada?.descricao}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            Detalhes: {tarefaSelecionada?.detalhes}
          </Typography>
        </Box>
      </Modal>

      {/* Feedback Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default PainelDeAtividades;
