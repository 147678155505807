import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const GrupoServico = () => {
  const [grupos, setGrupos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedGrupo, setSelectedGrupo] = useState(null); // Grupo selecionado para editar
  const [grupoForm, setGrupoForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar grupos de serviço com token JWT
  useEffect(() => {
    loadGruposServico();
  }, []);

  const loadGruposServico = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/grupo-servicos`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setGrupos(response.data))
      .catch(() => setErrorMessage('Erro ao carregar grupos de serviço.'));
  };

  // Função para abrir o modal
  const handleOpenModal = (type, grupo = null) => {
    setModalType(type);
    if (grupo) {
      setSelectedGrupo(grupo);
      setGrupoForm({ nome: grupo.nome });
    } else {
      setSelectedGrupo(null);
      setGrupoForm({ nome: '' }); // Limpar os campos ao abrir o modal para adicionar
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedGrupo(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar grupo de serviço
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!grupoForm.nome) {
      setErrorMessage('O nome do grupo é obrigatório.');
      return;
    }

    const formData = { nome: grupoForm.nome };

    if (modalType === 'edit' && selectedGrupo) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/grupo-servicos/${selectedGrupo.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Grupo de serviço atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadGruposServico();
        })
        .catch(() => setErrorMessage('Erro ao atualizar grupo de serviço.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/grupo-servicos`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Grupo de serviço criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadGruposServico();
        })
        .catch(() => setErrorMessage('Erro ao criar grupo de serviço.'));
    }
  };

  // Função para deletar grupo de serviço
  const handleDeleteGrupoServico = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/grupo-servicos/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Grupo de serviço deletado com sucesso.');
        setShowSnackbar(true);
        loadGruposServico();
      })
      .catch(() => setErrorMessage('Erro ao deletar grupo de serviço.'));
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Grupo de Serviço
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Grupo de Serviço</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grupos.map(grupo => (
            <TableRow key={grupo.id}>
              <TableCell>{grupo.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', grupo)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteGrupoServico(grupo.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Grupo de Serviço' : 'Editar Grupo de Serviço'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Grupo"
            fullWidth
            value={grupoForm.nome}
            onChange={(e) => setGrupoForm({ ...grupoForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default GrupoServico;
