import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const MainLayout = ({ children }) => {
  // Estado que controla se a sidebar está aberta ou fechada
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Começa com a sidebar aberta

  // Função para alternar a sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      
      {/* Header com controle para abrir/fechar a sidebar */}
      <Header toggleSidebar={toggleSidebar} />
      
      <Box sx={{ display: 'flex', flex: 1 }}>
        {/* Sidebar controlada pelo estado */}
        <Sidebar isSidebarOpen={isSidebarOpen} />
        
        {/* Conteúdo principal com ajuste de margem */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: '64px', // Move o conteúdo para abaixo do header
            ml: isSidebarOpen ? '20px' : '20px', // Ajusta a margem lateral com base no estado da sidebar
            transition: 'margin 0.3s ease', // Transição suave da margem
          }}
        >
          {children}
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default MainLayout;
