import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import InputMask from 'react-input-mask'; // Para máscara de telefone

const Escritorio = () => {
  const [escritorios, setEscritorios] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedEscritorio, setSelectedEscritorio] = useState(null);
  const [escritorioForm, setEscritorioForm] = useState({ nome: '', endereco: '', telefone: '', numero_do_escritorio: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar escritórios com token JWT
  useEffect(() => {
    loadEscritorios();
  }, []);

  const loadEscritorios = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/escritorios`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setEscritorios(response.data))
      .catch(() => setErrorMessage('Erro ao carregar escritórios.'));
  };

  // Função para abrir o modal
  const handleOpenModal = (type, escritorio = null) => {
    setModalType(type);
    if (escritorio) {
      setSelectedEscritorio(escritorio);
      setEscritorioForm({
        nome: escritorio.nome,
        endereco: escritorio.endereco,
        telefone: escritorio.telefone,
        numero_do_escritorio: escritorio.numero_do_escritorio
      });
    } else {
      setSelectedEscritorio(null);
      setEscritorioForm({ nome: '', endereco: '', telefone: '', numero_do_escritorio: '' }); // Limpar os campos ao abrir o modal para adicionar
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEscritorio(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar escritório
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!escritorioForm.nome || !escritorioForm.numero_do_escritorio) {
      setErrorMessage('O nome e o número do escritório são obrigatórios.');
      return;
    }

    const formData = {
      nome: escritorioForm.nome,
      endereco: escritorioForm.endereco,
      telefone: escritorioForm.telefone,
      numero_do_escritorio: escritorioForm.numero_do_escritorio
    };

    if (modalType === 'edit' && selectedEscritorio) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/escritorios/${selectedEscritorio.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Escritório atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadEscritorios();
        })
        .catch(() => setErrorMessage('Erro ao atualizar escritório.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/escritorios`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Escritório criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadEscritorios();
        })
        .catch(() => setErrorMessage('Erro ao criar escritório.'));
    }
  };

  // Função para deletar escritório
  const handleDeleteEscritorio = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/escritorios/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Escritório deletado com sucesso.');
        setShowSnackbar(true);
        loadEscritorios();
      })
      .catch(() => setErrorMessage('Erro ao deletar escritório.'));
  };

  // Máscara de telefone com verificação automática para celular/fixo
  const telefoneMask = (value) => {
    const onlyNumbers = value.replace(/\D/g, '');
    return onlyNumbers.length === 11 ? '(99) 99999-9999' : '(99) 9999-9999';
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Escritório
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Escritório</TableCell>
            <TableCell>Endereço</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Número do Escritório</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {escritorios.map(escritorio => (
            <TableRow key={escritorio.id}>
              <TableCell>{escritorio.nome}</TableCell>
              <TableCell>{escritorio.endereco}</TableCell>
              <TableCell>{escritorio.telefone}</TableCell>
              <TableCell>{escritorio.numero_do_escritorio}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', escritorio)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteEscritorio(escritorio.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Escritório' : 'Editar Escritório'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Escritório"
            fullWidth
            value={escritorioForm.nome}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Endereço"
            fullWidth
            value={escritorioForm.endereco}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, endereco: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <InputMask
            mask={telefoneMask(escritorioForm.telefone)}
            value={escritorioForm.telefone}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, telefone: e.target.value })}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                label="Telefone"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            )}
          </InputMask>

          <TextField
            label="Número do Escritório"
            fullWidth
            value={escritorioForm.numero_do_escritorio}
            onChange={(e) => {
              if (/^\d*$/.test(e.target.value)) {
                setEscritorioForm({ ...escritorioForm, numero_do_escritorio: e.target.value });
              }
            }}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default Escritorio;
