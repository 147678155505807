// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import Clientes from './pages/Clientes/Clientes';
import NovoCliente from './pages/Clientes/NovoCliente';
import EmpresasList from './pages/Empresas/EmpresasList';
import EmpresaForm from './pages/Empresas/EmpresaForm';
import RedefinirSenha from './pages/RedefinirSenha';
import Perfil from './pages/Perfil';
import ProtectedAdminRoute from './components/ProtectedAdminRoute'; // Rota protegida para admin


import GrupoCliente from './pages/Configuracoes/GrupoCliente'; // Página de Grupo de Cliente
import GrupoServico from './pages/Configuracoes/GrupoServico'; // Página de Grupo de Cliente
import StatusCliente from './pages/Configuracoes/StatusCliente'; // Página de Status Cliente
import StatusComplementar from './pages/Configuracoes/StatusComplementar'; // Página de Status Complementar
import CategoriaCliente from './pages/Configuracoes/CategoriaCliente'; // Página de Categoria de Clientes
import SegmentoEmpresa from './pages/Configuracoes/SegmentoEmpresa'; // Página de Segmento empresa
import TipoCliente from './pages/Configuracoes/TipoCliente'; // Página do tipo do cliene
import Escritorio from './pages/Configuracoes/Escritorio'; // Página de Escritório
import Departamento from './pages/Configuracoes/Departamento'; // Página do departamento
import TipoServico from './pages/Configuracoes/TipoServico'; // Página tipo do serviço
import Feriados from './pages/Configuracoes/Feriados'; // Página feriados
import EmailTemplates from './pages/Configuracoes/EmailTemplates'; // Página email
import WhatsappTemplates from './pages/Configuracoes/WhatsappTemplates'; // Página whatsapp
import HorarioAcesso from './pages/Configuracoes/HorarioAcesso'; // Página whatsapp
import UsuariosPermissoes from './pages/Configuracoes/UsuariosPermissoes'; // Página whatsapp
import Integracoes from './pages/Configuracoes/Integracoes'; // Página Integracoes
import RegimeTributario from './pages/Configuracoes/RegimeTributario'; // Página Regime Tributario 



import Servicos from './pages/Servicos/Servicos'; // Página servicos
import NovoServico from './pages/Servicos/NovoServico'; // Página novo servicos

import Obrigacoes from './pages/Obrigacoes/Obrigacoes'; // Página Obrigações
import NovaObrigacoes from './pages/Obrigacoes/NovaObrigacoes'; // Página Obrigações

import Controles from './pages/Controles/Controles'; // Página Obrigações
import OrdemServicos from './pages/OrdemServico/OrdemServico'; // Página Obrigações
import NovaOrdemServico from './pages/OrdemServico/NovaOrdemServico'; // Página Obrigações







import PainelAtividade from './pages/PainelAtividade/PainelAtividade'; // Página atividade



import ProtectedRoute from './components/ProtectedRoute'; // Importa o componente de Rota Protegida



// Criação do tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#6A1B9A', // Cor roxa similar ao design
    },
    secondary: {
      main: '#ff9800', // Cor laranja
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Rota de Login */}
          <Route path="/" element={<Login />} />

          {/* Rotas protegidas (somente acessíveis após login) */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Dashboard />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/NovoCliente"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovoCliente />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/NovoServico"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovoServico />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/painel-atividades"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <PainelAtividade />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/servicos"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Servicos />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/obrigacoes"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Obrigacoes />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/nova-obrigacoes"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovaObrigacoes />
                </MainLayout>
              </ProtectedRoute>
            }
          />



<Route
            path="/ordem-servico"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <OrdemServicos />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/nova-ordem-servico"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovaOrdemServico />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/controles"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Controles />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/clientes"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Clientes />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Rotas de Empresas protegidas */}
          <Route
            path="/empresas"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresasList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/empresas/novo"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaForm />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/empresas/editar/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaForm />
                </MainLayout>
              </ProtectedRoute>
            }
          />

           {/* Rota para grupo de cliente (acessível apenas por admin) */}
           <Route
              path="/grupo-cliente"
              element={
                <ProtectedAdminRoute>
                  <MainLayout>
                    <GrupoCliente />
                  </MainLayout>
                </ProtectedAdminRoute>
              }
            />


<Route
  path="/grupo-servico"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <GrupoServico />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/regime-tributario"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <RegimeTributario />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/status-cliente"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <StatusCliente />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/status-complementar"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <StatusComplementar />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>
<Route
  path="/categoria-cliente"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <CategoriaCliente />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/segmento-empresa"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <SegmentoEmpresa />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>



<Route
  path="/tipo-cliente"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <TipoCliente />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/escritorio"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Escritorio />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>





<Route
  path="/departamento"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Departamento />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/tipo-servico"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <TipoServico />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/calendario-feriados"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Feriados />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/usuarios"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <UsuariosPermissoes />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/template-email"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <EmailTemplates />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/template-whatsapp"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <WhatsappTemplates />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

Integracoes

<Route
  path="/integracoes"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Integracoes />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/horario-acesso"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <HorarioAcesso />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>





          <Route
            path="/empresas/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaForm />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          {/* Rota de Perfil (protegida) */}
          <Route
            path="/perfil"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Perfil />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Rota para redefinir senha (acessível sem login) */}
          <Route path="/redefinir-senha/:token" element={<RedefinirSenha />} />

          {/* Página 404 (Not Found) */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
