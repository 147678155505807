import React, { useMemo, useState } from 'react';
import { 
  Box, Button, IconButton, Tooltip, Modal, Typography, 
  TextField, Grid, Avatar, Chip, InputAdornment 
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { 
  Delete, Edit, Visibility, Print, SaveAlt, 
  ViewModule, ViewList, Search 
} from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Gera dados simulados para controles
const generateControlesData = (numControles) => {
  const tiposControle = ['Fluxo de Caixa', 'Conciliação Bancária', 'DRE Mensal', 'Controle de Despesas'];
  
  return Array.from({ length: numControles }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    tipoControle: tiposControle[i % tiposControle.length],
    ultimoLancamento: `2024-10-${(i % 30) + 1}`,
    status: i % 2 === 0 ? 'Em dia' : 'Atrasado',
  }));
};

const Controles = () => {
  const [data, setData] = useState(generateControlesData(300));
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [viewMode, setViewMode] = useState('table');
  const [selectedControl, setSelectedControl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filtroStatus, setFiltroStatus] = useState('todos');

  const filteredData = useMemo(() => {
    let filteredControls = data;
    if (filtroStatus !== 'todos') {
      filteredControls = filteredControls.filter(c => c.status === filtroStatus);
    }
    if (searchTerm) {
      filteredControls = filteredControls.filter(c =>
        c.cliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.tipoControle.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return filteredControls;
  }, [data, filtroStatus, searchTerm]);

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID', size: 50 },
    { accessorKey: 'cliente', header: 'Cliente', size: 200 },
    { accessorKey: 'tipoControle', header: 'Controle', size: 250 },
    { accessorKey: 'ultimoLancamento', header: 'Último Lançamento', size: 150 },
    { accessorKey: 'status', header: 'Status', size: 100 },
    {
      header: 'Ações',
      size: 150,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Tooltip arrow title="Visualizar">
            <IconButton color="info" onClick={() => handleView(row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Editar">
            <IconButton color="primary" onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Remover">
            <IconButton color="error" onClick={() => handleRemove(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], []);

  const handleView = (row) => {
    setSelectedControl(row.original);
    setModalContent('view');
    setModalOpen(true);
  };

  const handleEdit = (row) => {
    setSelectedControl(row.original);
    setModalContent('edit');
    setModalOpen(true);
  };

  const handleRemove = (row) => {
    setSelectedControl(row.original);
    setModalContent('remove');
    setModalOpen(true);
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Controles Gerenciais', 20, 10);
    doc.autoTable({
      head: [['ID', 'Cliente', 'Controle', 'Último Lançamento', 'Status']],
      body: data.map(c => [c.id, c.cliente, c.tipoControle, c.ultimoLancamento, c.status]),
    });
    doc.save('controles.pdf');
  };

  const toggleViewMode = () => setViewMode(viewMode === 'table' ? 'cards' : 'table');

  const renderModalContent = () => {
    if (!selectedControl) return null;

    if (modalContent === 'view') {
      return (
        <>
          <Typography variant="h6">Visualizar Controle</Typography>
          <Typography><b>ID:</b> {selectedControl.id}</Typography>
          <Typography><b>Cliente:</b> {selectedControl.cliente}</Typography>
          <Typography><b>Controle:</b> {selectedControl.tipoControle}</Typography>
          <Typography><b>Último Lançamento:</b> {selectedControl.ultimoLancamento}</Typography>
          <Typography><b>Status:</b> {selectedControl.status}</Typography>
        </>
      );
    }

    if (modalContent === 'edit') {
      return (
        <>
          <Typography variant="h6">Editar Controle</Typography>
          <TextField fullWidth label="Controle" defaultValue={selectedControl.tipoControle} sx={{ mb: 2 }} />
          <TextField fullWidth label="Último Lançamento" defaultValue={selectedControl.ultimoLancamento} sx={{ mb: 2 }} />
          <Button variant="contained" color="primary" onClick={() => alert('Controle atualizado!')}>
            Salvar
          </Button>
        </>
      );
    }

    if (modalContent === 'remove') {
      return (
        <>
          <Typography variant="h6">Remover Controle</Typography>
          <Typography>Tem certeza que deseja remover o controle de <b>{selectedControl.cliente}</b>?</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
            <Button variant="contained" color="error" onClick={() => alert(`Controle removido`)}>
              Remover
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(false)}>Cancelar</Button>
          </Box>
        </>
      );
    }
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={viewMode === 'table' ? <ViewModule /> : <ViewList />}
          onClick={toggleViewMode}
        >
          Alternar para {viewMode === 'table' ? 'Cards' : 'Tabela'}
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" color="info" onClick={exportPDF} startIcon={<SaveAlt />}>
            Exportar PDF
          </Button>
          <Button variant="contained" color="secondary" onClick={() => window.print()} startIcon={<Print />}>
            Imprimir
          </Button>
        </Box>
      </Box>

      <MaterialReactTable columns={columns} data={filteredData} />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
          {renderModalContent()}
        </Box>
      </Modal>
    </Box>
  );
};

export default Controles;
