import React, { useState, useEffect } from 'react';
import {
  Box, Grid, TextField, Button, Select, MenuItem, Typography, Paper,
  FormControl, InputLabel, Card, Checkbox, CardContent, Collapse, FormControlLabel, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, List, ListItem, ListItemText
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material'; // Importando o ícone Add corretamente
import axios from 'axios';
import { Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Coloque esta importação no topo do arquivo


const token = localStorage.getItem('token'); // Certifique-se de que o token está salvo e recuperável
const headers = { Authorization: `Bearer ${token}` };




const API_BASE_URL = process.env.REACT_APP_API_URL;

const NovaObrigacoes = () => {
  const [formData, setFormData] = useState({
    codigo: '', classificacao: '', nomeObrigacao: '', minimome: '',
    departamento: '', tipoServico: '', grupoServico: '', esfera: '',
    diasAntecipacao: '', diasInicioServico: '', tipoDiasAntes: 'uteis',
    diasNaoUteis: 'Não', sabadoUtil: 'Não', competencia: '', 
    exigirRobo: 'Não', multa: 'Não', alertaGuia: 'Não', 
    notificarCliente: 'Não', liberarAppCliente: 'Não', gerarPara: '', 
    observacao: '', janeiro: '', fevereiro: '', marco: '', abril: '', 
    maio: '', junho: '', julho: '', agosto: '', setembro: '', 
    outubro: '', novembro: '', dezembro: '', obrigatorioChecklist: 'Não', 
    prioridade: '', isParcelamento: false, servicoParcelamento: '', parcelaOpcional: '',
  });
  

  const navigate = useNavigate(); // Inicialize o hook useNavigate
  

   // Função para voltar para a página anterior ou uma página específica
   const handleVoltar = () => {
    navigate(-1); // Volta para a página anterior no histórico
    // Ou use navigate('/rota-desejada'); para ir para uma página específica
  };
  
  // Estado para feedback do Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [parcelas, setParcelas] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');

  const [isParcelamentoSelected, setIsParcelamentoSelected] = useState(false);
  const [parcelamentoOptions, setParcelamentoOptions] = useState([
    { id: 'sefaz', nome: 'Emissão das guias de parcelamento da SEFAZ' },
    { id: 'receita', nome: 'Emissão das guias de parcelamento da Receita' },
  ]);

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setSnackbarMessage("Token não encontrado. Faça login novamente.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
      return;
    }
  
    const headers = { Authorization: `Bearer ${token}` };
  
    // Adicione logs para verificar os valores de formData
    console.log("Valores de formData:", formData);
  
    // Verificar se os campos obrigatórios estão preenchidos antes de enviar
    if (!formData.codigo || !formData.nomeObrigacao) {
      setSnackbarMessage("O nome e o código da obrigação são obrigatórios.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
      return;
    }
  
    try {
      // Dados para a API de obrigacoes
      const obrigacoesData = {
        codigo: formData.codigo,
        classificacao: formData.classificacao,
        nomeObrigacao: formData.nomeObrigacao,
        minimome: formData.minimome,
        observacao: formData.observacao,
      };
  
      console.log("Enviando dados para /api/obrigacoes:", obrigacoesData);
      const obrigacaoResponse = await axios.post(`${API_BASE_URL}/api/obrigacoes`, obrigacoesData, { headers });
      const obrigacao_id = obrigacaoResponse.data.id;
  
      // Dados para a API de entregas-mensais
      const entregasMensaisData = {
        obrigacao_id,
        janeiro: formData.janeiro,
        fevereiro: formData.fevereiro,
        marco: formData.marco,
        abril: formData.abril,
        maio: formData.maio,
        junho: formData.junho,
        julho: formData.julho,
        agosto: formData.agosto,
        setembro: formData.setembro,
        outubro: formData.outubro,
        novembro: formData.novembro,
        dezembro: formData.dezembro,
      };
  
      console.log("Enviando dados para /api/entregas-mensais:", entregasMensaisData);
      await axios.post(`${API_BASE_URL}/api/entregas-mensais`, entregasMensaisData, { headers });
  
      // Dados para a API de prazos-obrigacoes
      const prazosObrigacoesData = {
        obrigacao_id,
        diasAntecipacao: formData.diasAntecipacao,
        diasInicioServico: formData.diasInicioServico,
        tipoDiasAntes: formData.tipoDiasAntes,
        prazosFixos: formData.diasNaoUteis,
        sabadoUtil: formData.sabadoUtil,
        competenciaReferenteA: formData.competencia,
      };
  
      console.log("Enviando dados para /api/prazos-obrigacoes:", prazosObrigacoesData);
      await axios.post(`${API_BASE_URL}/api/prazos-obrigacoes`, prazosObrigacoesData, { headers });
  
      // Dados para a API de configuracoes-servico
      const configuracoesServicoData = {
        obrigacao_id,
        exigirRobo: formData.exigirRobo,
        multa: formData.multa,
        alertaGuia: formData.alertaGuia,
        notificarCliente: formData.notificarCliente,
        liberarAppCliente: formData.liberarAppCliente,
        gerarPara: formData.gerarPara,
        prioridade: formData.prioridade,
        esfera: formData.esfera,
        obrigatorioChecklist: formData.obrigatorioChecklist,
      };
  
      console.log("Enviando dados para /api/configuracoes-servico:", configuracoesServicoData);
      await axios.post(`${API_BASE_URL}/api/configuracoes-servico`, configuracoesServicoData, { headers });
  
      // Dados para a API de parcelamento-obrigacoes (opcional)
      if (formData.isParcelamento) {
        const parcelamentoObrigacoesData = {
          obrigacao_id,
          isParcelamento: formData.isParcelamento,
          servicoParcelamento: formData.servicoParcelamento,
          parcelaOpcional: formData.parcelaOpcional,
        };
  
        console.log("Enviando dados para /api/parcelamento-obrigacoes:", parcelamentoObrigacoesData);
        await axios.post(`${API_BASE_URL}/api/parcelamento-obrigacoes`, parcelamentoObrigacoesData, { headers });
      }
  
      setSnackbarMessage("Serviço cadastrado com sucesso!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
  
      // Resetando o formData
      setFormData({
        codigo: "", classificacao: "", nomeObrigacao: "", minimome: "",
        departamento: "", tipoServico: "", grupoServico: "", esfera: "",
        diasAntecipacao: "", diasInicioServico: "", tipoDiasAntes: "uteis",
        diasNaoUteis: "Não", sabadoUtil: "Não", competencia: "", 
        exigirRobo: "Não", multa: "Não", alertaGuia: "Não", 
        notificarCliente: "Não", liberarAppCliente: "Não", gerarPara: "", 
        observacao: "", janeiro: "", fevereiro: "", marco: "", abril: "", 
        maio: "", junho: "", julho: "", agosto: "", setembro: "", 
        outubro: "", novembro: "", dezembro: "", obrigatorioChecklist: "Não", 
        prioridade: "", isParcelamento: false, servicoParcelamento: "", parcelaOpcional: "",
      });
    } catch (error) {
      if (error.response) {
        console.error("Erro na resposta do servidor:", error.response.data);
        setSnackbarMessage(`Erro ao cadastrar o serviço: ${error.response.data.message}`);
      } else {
        console.error("Erro na solicitação:", error.message);
        setSnackbarMessage("Erro na solicitação ao servidor.");
      }
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };
  
  
  

  // Estados para armazenar as opções carregadas da API
  const [departamentos, setDepartamentos] = useState([]);
  const [tiposServico, setTiposServico] = useState([]);
  const [gruposServico, setGruposServico] = useState([]);


  
  // Função para carregar dados do backend
  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('token'); // Obtém o token armazenado
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const [departamentoRes, tipoServicoRes, grupoServicoRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/departamento`, { headers }),
          axios.get(`${API_BASE_URL}/api/tipo-servico`, { headers }),
          axios.get(`${API_BASE_URL}/api/grupo-servicos`, { headers }),
        ]);

        setDepartamentos(departamentoRes.data);
        setTiposServico(tipoServicoRes.data);
        setGruposServico(grupoServicoRes.data);
      } catch (error) {
        console.error('Erro ao carregar dados das opções:', error);
      }
    };

    fetchOptions();
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

// Estado para controlar qual checkbox está selecionado
const [checkboxSelecionado, setCheckboxSelecionado] = useState(null);

const handleCheckboxChange = (option) => {
  // Define qual checkbox foi selecionado; desmarque o outro checkbox
  setCheckboxSelecionado(option === checkboxSelecionado ? null : option);
};
  

  return (

    
    <Box sx={{ padding: 4, maxWidth: 1400, margin: 'auto', backgroundColor: '#ffffff' }}>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', mb: 3}}>
        Cadastrar Nova Obrigação 
      </Typography>

      <Paper elevation={3} sx={{ padding: 3, borderRadius: 3, backgroundColor: '#fff' }}>
        {/* Seção: Informações Gerais */}
        <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>Informações Gerais</Typography>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth label="Código" name="codigo"
              value={formData.codigo} onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth label="Classificação" name="classificacao"
              value={formData.classificacao} onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth label="Nome da Obrigação" name="nomeObrigacao"
              value={formData.nomeObrigacao} onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth label="Mininome" name="minimome"
              value={formData.minimome} onChange={handleChange}
            />
          </Grid>


          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Departamento</InputLabel>
              <Select
                name="departamento"
                value={formData.departamento}
                onChange={handleChange}
              >
                {departamentos.map((departamento) => (
                  <MenuItem key={departamento.id} value={departamento.id}>
                    {departamento.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
              <InputLabel>Tipo do Serviço</InputLabel>
              <Select
                name="tipoServico"
                value={formData.tipoServico}
                onChange={handleChange}
              >
                {tiposServico.map((tipo) => (
                  <MenuItem key={tipo.id} value={tipo.id}>
                    {tipo.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
                                    </Grid>



                                    <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth variant="outlined">
              <InputLabel>Grupo do Serviço</InputLabel>
              <Select
                name="grupoServico"
                value={formData.grupoServico}
                onChange={handleChange}
              >
                {gruposServico.map((grupo) => (
                  <MenuItem key={grupo.id} value={grupo.id}>
                    {grupo.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
                                    </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Seção: Entregas Mensais */}
        <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>Entregas Mensais</Typography>
        <Grid container spacing={2}>
          {[
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ].map((mes, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FormControl fullWidth>
                <InputLabel>Entrega {mes}</InputLabel>
                <Select>
                  <MenuItem value="Não tem">Não tem</MenuItem>
                  {Array.from({ length: 31 }).map((_, day) => (
                    <MenuItem value={day + 1} key={day}>
                      Todo dia {day + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Seção: Prazos e Configurações */}
        <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>Prazos e Configurações</Typography>
        <Grid container spacing={3} sx={{ mt: 1 }}>
         
        <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para antecipar a entrega ao cliente" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para iniciar o serviço" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Tipo dos dias Antes</InputLabel>
                                            <Select label="Tipo dos dias Antes">
                                                <MenuItem value="Dias úteis">Dias úteis</MenuItem>
                                                <MenuItem value="Dias corridos">Dias corridos</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Prazos fixos em dias não úteis</InputLabel>
                                            <Select label="Prazos fixos em dias não úteis">
                                                <MenuItem value="Antecipar para o dia útil anterior">Antecipar para o dia útil anterior</MenuItem>
                                                <MenuItem value="Postergar para o próximo dia útil">Postergar para o próximo dia útil</MenuItem>
                                                <MenuItem value="Manter o dia exato">Manter o dia exato</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Sábado é útil?</InputLabel>
                                            <Select label="Sábado é útil?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Competência referente a</InputLabel>
                                            <Select label="Competência referente a">
                                                <MenuItem value="Mês anterior">Mês anterior</MenuItem>
                                                <MenuItem value="2 meses antes">2 meses antes</MenuItem>
                                                <MenuItem value="3 meses antes">3 meses antes</MenuItem>
                                                <MenuItem value="Ano anterior">Ano anterior</MenuItem>
                                                <MenuItem value="Ano atual">Ano atual</MenuItem>
                                                <MenuItem value="Mês atual">Mês atual</MenuItem>
                                                <MenuItem value="Mês seguinte">Mês seguinte</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Exigir Robô?</InputLabel>
                                            <Select label="Exigir Robô?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Passível de multa?</InputLabel>
                                            <Select label="Passível de multa?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Alerta guia não lida?</InputLabel>
                                            <Select label="Alerta guia não lida?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Obrigatório Check List?</InputLabel>
                                            <Select label="Obrigatório Check List?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Esfera</InputLabel>
                                            <Select label="Esfera">
                                                <MenuItem value="Federal">Federal</MenuItem>
                                                <MenuItem value="Estadual">Estadual</MenuItem>
                                                <MenuItem value="Municipal">Municipal</MenuItem>
                                                <MenuItem value="Interno">Interno</MenuItem>
                                                <MenuItem value="Outros">Outros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Notificar o Cliente?</InputLabel>
                                            <Select label="Notificar o Cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Serviço liberado no APP do cliente?</InputLabel>
                                            <Select label="Serviço liberado no APP do cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Gerar para</InputLabel>
                                            <Select label="Gerar para">
                                                <MenuItem value="Matriz/Filial">Matriz/Filial</MenuItem>
                                                <MenuItem value="Apenas Matriz">Apenas Matriz</MenuItem>
                                                <MenuItem value="Apenas Filial">Apenas Filial</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

         

        </Grid>

        <Divider sx={{ my: 2 }} />

          {/* Seção: Configurações do Serviço */}
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>Configurações</Typography>
      <Card sx={{ mt: 2, padding: 2, backgroundColor: '#e3f2fd', boxShadow: 3 }}>
        <CardContent>
          <Grid container spacing={1}>
            {/* Checkboxes de Configuração */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <FormControl>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Obrigação"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Ordem de Serviço"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Controle"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>

            {/* Campo de Prioridade */}
            <Grid container spacing={0} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Prioridade</InputLabel>
                  <Select>
                    <MenuItem value={1}>Alta</MenuItem>
                    <MenuItem value={2}>Média</MenuItem>
                    <MenuItem value={3}>Baixa</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ padding: 0, maxWidth: 1200,  backgroundColor: '#e3f2fd' }}>

            {/* Seção de Parcelamento */}
            <Typography variant="h6" sx={{ mt: 2, color: '#1976d2', fontWeight: 'bold' }}>Parcelamento</Typography>
            <Grid container spacing={0} sx={{ mt: 1 }}>
              <Grid item xs={12}>
              <FormControl>
      <Grid container spacing={1}>
        {/* Checkbox para "É um serviço de parcelamento" */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxSelecionado === "isParcelamento"}
                onChange={() => handleCheckboxChange("isParcelamento")}
              />
            }
            label="É um serviço de parcelamento"
          />
        </Grid>
        
        {/* Checkbox para "Serviço utilizado para verificar e gerar parcelamento utilizando o serviço abaixo para parcelar" */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxSelecionado === "servicoParcelamento"}
                onChange={() => handleCheckboxChange("servicoParcelamento")}
              />
            }
            label="Serviço utilizado para verificar e gerar parcelamento utilizando o serviço abaixo para parcelar"
          />
        </Grid>
      </Grid>
    </FormControl>
              </Grid>
 {/* Seleção de Tipo de Parcelamento (aparece apenas para "servicoParcelamento") */}


{/* Seleção de Tipo de Parcelamento (aparece apenas para "servicoParcelamento") */}
<Collapse in={checkboxSelecionado === "servicoParcelamento"} sx={{ width: '100%' }}>
  <Grid container spacing={2} alignItems="center">
    <Grid item xs={12} sm={5}>
      <FormControl fullWidth>
        <InputLabel>Tipo de Parcelamento</InputLabel>
        <Select
          name="tipoParcelamento"
          defaultValue=""
          onChange={(e) => setFormData({ ...formData, tipoParcelamento: e.target.value })}
        >
          <MenuItem value="federal">Parcelamento Federal</MenuItem>
          <MenuItem value="estadual">Parcelamento Estadual</MenuItem>
          <MenuItem value="outros">Outros</MenuItem>
        </Select>
      </FormControl>
    </Grid>

    {/* Campo para Quantidade de Parcelas */}
   

   
  </Grid>

  {/* Mensagem de Aviso */}
  
</Collapse>

          

            </Grid>
            </Box>
          </Grid>
        </CardContent>
          
        </Card>
        

        <Divider sx={{ my: 4 }} />

        {/* Botões de Ação */}
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ fontWeight: 'bold' }} 
            onClick={handleSubmit}
          >
            Salvar
          </Button>

          <Snackbar
  open={showSnackbar}
  autoHideDuration={6000}
  onClose={() => setShowSnackbar(false)}
>
  <Alert onClose={() => setShowSnackbar(false)} severity={snackbarSeverity}>
    {snackbarMessage}
  </Alert>
</Snackbar>




<Button 
            variant="outlined" 
            color="secondary" 
            sx={{ fontWeight: 'bold' }} 
            onClick={handleVoltar} // Chama a função handleVoltar ao clicar
          >
            Voltar
          </Button>
          </Grid>
        </Grid>

      </Paper>
    </Box>
  );
};

export default NovaObrigacoes;
