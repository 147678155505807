import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';
import ArticleIcon from '@mui/icons-material/Article';
import SchoolIcon from '@mui/icons-material/School';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        p: 2, 
        backgroundColor: '#f5f5f5', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        borderTop: '1px solid #e0e0e0' 
      }}
    >
      {/* Alinhamento à esquerda com um possível espaço ou conteúdo adicional */}
      <Box />

      {/* Alinhamento à direita com o título e ícones */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: 'bold', 
            fontSize: '12px', 
            color: '#000', 
            marginRight: '16px' // Espaçamento entre texto e ícones 
          }}
        >
          Iryd Sistemas
        </Typography>

        <IconButton
          sx={{ color: '#FFA500', mx: 0.5 }}
          href="https://suporte.iryd.com"
          target="_blank"
          aria-label="Suporte"
        >
          <SupportIcon />
        </IconButton>

        <IconButton
          sx={{ color: '#FFA500', mx: 0.5 }}
          href="https://blog.iryd.com"
          target="_blank"
          aria-label="Blog"
        >
          <ArticleIcon />
        </IconButton>

        <IconButton
          sx={{ color: '#FFA500', mx: 0.5 }}
          href="https://treinamento.iryd.com"
          target="_blank"
          aria-label="Treinamento"
        >
          <SchoolIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
