import React, { useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Modal, Typography, TextField, Grid, Avatar, Chip, Autocomplete, Checkbox } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Delete, Edit, Visibility, Print, SaveAlt, ViewModule, ViewList, Email, Phone, Search, NearMeOutlined } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

const generateClientesData = (num) => {
  return Array.from({ length: num }).map((_, i) => ({
    id: i + 1,
    nome: `Cliente ${i + 1}`,
    email: `cliente${i + 1}@email.com`,
    telefone: '123-456-789',
    status: i % 2 === 0 ? 'Ativo' : 'Inativo',
  }));
};

const Clientes = () => {
  const [data, setData] = useState(generateClientesData(500));
  const [hasMore, setHasMore] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [viewMode, setViewMode] = useState('table');
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [nomeFilter, setNomeFilter] = useState([]);
  const [emailFilter, setEmailFilter] = useState([]);
  const [telefoneFilter, setTelefoneFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const navigate = useNavigate();

  const filteredData = useMemo(() => {
    return data.filter(cliente => {
      return (
        (nomeFilter.length === 0 || nomeFilter.includes(cliente.nome)) &&
        (emailFilter.length === 0 || emailFilter.includes(cliente.email)) &&
        (telefoneFilter.length === 0 || telefoneFilter.includes(cliente.telefone)) &&
        (statusFilter.length === 0 || statusFilter.includes(cliente.status))
      );
    });
  }, [data, nomeFilter, emailFilter, telefoneFilter, statusFilter]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'nome',
        header: 'Nome',
        enableColumnOrdering: true,
        enableSorting: true,
        filterVariant: 'multi-select',
        Filter: () => (
          <Autocomplete
            multiple
            options={[...new Set(data.map((cliente) => cliente.nome))]}
            value={nomeFilter}
            onChange={(event, newValue) => setNomeFilter(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Filtrar Nome" />
            )}
          />
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableColumnOrdering: true,
        enableSorting: true,
        filterVariant: 'multi-select',
        Filter: () => (
          <Autocomplete
            multiple
            options={[...new Set(data.map((cliente) => cliente.email))]}
            value={emailFilter}
            onChange={(event, newValue) => setEmailFilter(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Filtrar Email" />
            )}
          />
        ),
      },
      {
        accessorKey: 'telefone',
        header: 'Telefone',
        enableColumnOrdering: true,
        enableSorting: true,
        filterVariant: 'multi-select',
        Filter: () => (
          <Autocomplete
            multiple
            options={[...new Set(data.map((cliente) => cliente.telefone))]}
            value={telefoneFilter}
            onChange={(event, newValue) => setTelefoneFilter(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Filtrar Telefone" />
            )}
          />
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnOrdering: true,
        enableSorting: true,
        filterVariant: 'multi-select',
        Filter: () => (
          <Autocomplete
            multiple
            options={['Ativo', 'Inativo']}
            value={statusFilter}
            onChange={(event, newValue) => setStatusFilter(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Filtrar Status" />
            )}
          />
        ),
      },
      {
        header: 'Ações',
        size: 150,
        enablePinning: true,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <Tooltip arrow title="Visualizar">
              <IconButton color="info" onClick={() => handleView(row)}>
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Editar">
              <IconButton color="primary" onClick={() => handleEdit(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Remover">
              <IconButton color="error" onClick={() => handleRemove(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [nomeFilter, emailFilter, telefoneFilter, statusFilter, data]
  );

  const handleView = (row) => {
    setSelectedCliente(row.original);
    setModalContent('view');
    setModalOpen(true);
  };

  const handleEdit = (row) => {
    setSelectedCliente(row.original);
    setModalContent('edit');
    setModalOpen(true);
  };

  const handleRemove = (row) => {
    setSelectedCliente(row.original);
    setModalContent('remove');
    setModalOpen(true);
  };

  const exportCSV = () => {
    const csvData = data.map((cliente) => ({
      Nome: cliente.nome,
      Email: cliente.email,
      Telefone: cliente.telefone,
      Status: cliente.status,
    }));

    const csvString = [
      ['Nome', 'Email', 'Telefone', 'Status'],
      ...csvData.map(cliente => [
        cliente.Nome,
        cliente.Email,
        cliente.Telefone,
        cliente.Status
      ])
    ]
    .map(e => e.join(","))
    .join("\n");

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "clientes.csv";
    link.click();
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Clientes', 20, 10);
    doc.autoTable({
      head: [['Nome', 'Email', 'Telefone', 'Status']],
      body: data.map((cliente) => [cliente.nome, cliente.email, cliente.telefone, cliente.status]),
    });
    doc.save('clientes.pdf');
  };

  const fetchMoreData = () => {
    if (data.length >= 600) {
      setHasMore(false);
      return;
    }
    setData((prevData) => [
      ...prevData,
      ...generateClientesData(10).map((cliente, i) => ({
        ...cliente,
        id: prevData.length + i + 1,
      })),
    ]);
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'table' ? 'cards' : 'table');
  };

  const renderModalContent = () => {
    if (!selectedCliente) return null;

    if (modalContent === 'view') {
      return (
        <>
          <Typography variant="h6">Visualizar Cliente</Typography>
          <Typography><b>Nome:</b> {selectedCliente.nome}</Typography>
          <Typography><b>Email:</b> {selectedCliente.email}</Typography>
          <Typography><b>Telefone:</b> {selectedCliente.telefone}</Typography>
          <Typography><b>Status:</b> {selectedCliente.status}</Typography>
        </>
      );
    }

    if (modalContent === 'edit') {
      return (
        <>
          <Typography variant="h6">Editar Cliente</Typography>
          <TextField fullWidth label="Nome" defaultValue={selectedCliente.nome} sx={{ mb: 2 }} />
          <TextField fullWidth label="Email" defaultValue={selectedCliente.email} sx={{ mb: 2 }} />
          <TextField fullWidth label="Telefone" defaultValue={selectedCliente.telefone} sx={{ mb: 2 }} />
          <Button variant="contained" color="primary" onClick={() => alert('Cliente atualizado!')}>Salvar</Button>
        </>
      );
    }

    if (modalContent === 'remove') {
      return (
        <>
          <Typography variant="h6">Remover Cliente</Typography>
          <Typography>Tem certeza que deseja remover o cliente <b>{selectedCliente.nome}</b>?</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
            <Button variant="contained" color="error" onClick={() => alert(`Cliente ${selectedCliente.nome} removido`)}>
              Remover
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(false)}>Cancelar</Button>
          </Box>
        </>
      );
    }
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={viewMode === 'table' ? <ViewModule /> : <ViewList />}
          onClick={toggleViewMode}
        >
          Alternar para {viewMode === 'table' ? 'Cards' : 'Tabela'}
        </Button>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="outlined" color="primary" onClick={() => navigate('/NovoCliente')} startIcon={<NearMeOutlined />} sx={{ textTransform: 'none' }}>
            Adicionar 
          </Button>
          <Button variant="contained" color="success" onClick={exportCSV} startIcon={<SaveAlt />} sx={{ textTransform: 'none' }}>
            Exportar CSV
          </Button>
          <Button variant="contained" color="info" onClick={exportPDF} startIcon={<SaveAlt />} sx={{ textTransform: 'none' }}>
            Exportar PDF
          </Button>
          <Button variant="contained" color="secondary" onClick={() => window.print()} startIcon={<Print />} sx={{ textTransform: 'none' }}>
            Imprimir
          </Button>
        </Box>
      </Box>

      {viewMode === 'table' ? (
        <MaterialReactTable
          columns={columns}
          data={filteredData}
          enableInfiniteScroll
          enableColumnResizing
          enablePinning
          enableColumnOrdering
          muiTableBodyRowProps={{
            sx: {
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            },
          }}
          onScrollBottom={fetchMoreData}
          hasMore={hasMore}
        />
      ) : (
        <Grid container spacing={3} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {filteredData.map(cliente => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={cliente.id}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)' },
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: 180
                }}
                onClick={() => handleView({ original: cliente })}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: '#42a5f5', mr: 2 }}>{cliente.nome.charAt(0)}</Avatar>
                  <Typography variant="h6" sx={{ mb: 1 }}>{cliente.nome}</Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Email sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 1, color: '#616161' }} />
                  {cliente.email}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <Phone sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 1, color: '#616161' }} />
                  {cliente.telefone}
                </Typography>
                <Chip
                  label={cliente.status}
                  color={cliente.status === 'Ativo' ? 'success' : 'default'}
                  sx={{ alignSelf: 'flex-start', mt: 2 }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}>
          {renderModalContent()}
        </Box>
      </Modal>
    </Box>
  );
};

export default Clientes;
