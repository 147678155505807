import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const StatusComplementar = () => {
  const [statusComplementares, setStatusComplementares] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedStatus, setSelectedStatus] = useState(null); // Status selecionado para editar
  const [statusForm, setStatusForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar status complementares com token JWT
  useEffect(() => {
    loadStatusComplementares();
  }, []);

  const loadStatusComplementares = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/status-complementares`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setStatusComplementares(response.data))
      .catch(() => setErrorMessage('Erro ao carregar status complementares.'));
  };

  // Função para abrir o modal
  const handleOpenModal = (type, status = null) => {
    setModalType(type);
    if (status) {
      setSelectedStatus(status);
      setStatusForm({ nome: status.nome });
    } else {
      setSelectedStatus(null);
      setStatusForm({ nome: '' }); // Limpar os campos ao abrir o modal para adicionar
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStatus(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar status complementar
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!statusForm.nome) {
      setErrorMessage('O nome do status é obrigatório.');
      return;
    }

    const formData = {
      nome: statusForm.nome
    };

    if (modalType === 'edit' && selectedStatus) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/status-complementares/${selectedStatus.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Status complementar atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadStatusComplementares();
        })
        .catch(() => setErrorMessage('Erro ao atualizar status complementar.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/status-complementares`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Status complementar criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadStatusComplementares();
        })
        .catch(() => setErrorMessage('Erro ao criar status complementar.'));
    }
  };

  // Função para deletar status complementar
  const handleDeleteStatusComplementar = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/status-complementares/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Status complementar deletado com sucesso.');
        setShowSnackbar(true);
        loadStatusComplementares();
      })
      .catch(() => setErrorMessage('Erro ao deletar status complementar.'));
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Status Complementar
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusComplementares.map(status => (
            <TableRow key={status.id}>
              <TableCell>{status.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', status)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteStatusComplementar(status.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Status Complementar' : 'Editar Status Complementar'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Status"
            fullWidth
            value={statusForm.nome}
            onChange={(e) => setStatusForm({ ...statusForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default StatusComplementar;
