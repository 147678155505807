import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, InputAdornment, Alert, Snackbar,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import axios from 'axios';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Close as CloseIcon
} from '@mui/icons-material';

const EmpresasList = () => {
  const [empresas, setEmpresas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit', 'view', 'delete'
  const [selectedEmpresa, setSelectedEmpresa] = useState(null); // Empresa selecionada
  const [showPassword, setShowPassword] = useState(false); // Alternar visualização da senha
  const [empresaForm, setEmpresaForm] = useState({
    nome: '',
    cnpj: '',
    endereco: '',
    telefone: '',
    email: '',
    senha: '',
    data_inicio_assinatura: '',
    data_fim_assinatura: '',
    status_assinatura: '', // Adicionado aqui
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carrega a lista de empresas ao montar o componente
  useEffect(() => {
    loadEmpresas();
  }, []);

  // Função para carregar empresas do backend
  const loadEmpresas = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/empresas`)
      .then(response => setEmpresas(response.data))
      .catch(error => {
        if (error.response) {
          console.error('Erro ao carregar empresas:', error.response.data);
          setErrorMessage(`Erro: ${error.response.data.message || 'Não foi possível carregar as empresas.'}`);
        } else if (error.request) {
          console.error('Erro ao carregar empresas:', error.request);
          setErrorMessage('Erro: Sem resposta do servidor. Verifique sua conexão.');
        } else {
          console.error('Erro ao carregar empresas:', error.message);
          setErrorMessage(`Erro: ${error.message}`);
        }
      });
  };

  // Função para abrir o modal
  const handleOpenModal = (type, empresa = null) => {
    setModalType(type);
    if (empresa) {
      setSelectedEmpresa(empresa);
      setEmpresaForm({
        nome: empresa.nome || '',
        cnpj: empresa.cnpj || '',
        endereco: empresa.endereco || '',
        telefone: empresa.telefone || '',
        email: empresa.email || '',
        senha: '',
        data_inicio_assinatura: empresa.data_inicio_assinatura ? empresa.data_inicio_assinatura.split('T')[0] : '',
        data_fim_assinatura: empresa.data_fim_assinatura ? empresa.data_fim_assinatura.split('T')[0] : '',
        status_assinatura: empresa.status_assinatura || '', // Adicionado aqui
      });
    } else {
      setSelectedEmpresa(null);
      setEmpresaForm({
        nome: '',
        cnpj: '',
        endereco: '',
        telefone: '',
        email: '',
        senha: '',
        data_inicio_assinatura: '',
        data_fim_assinatura: '',
        status_assinatura: 'ativa', // Valor padrão ao adicionar
      });
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEmpresa(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar empresa
  const handleSubmitForm = () => {
    if (!empresaForm.nome || !empresaForm.cnpj || !empresaForm.email || !empresaForm.data_inicio_assinatura || !empresaForm.data_fim_assinatura || !empresaForm.status_assinatura) {
      setErrorMessage('Preencha todos os campos obrigatórios.');
      return;
    }

    if (modalType === 'add' && !empresaForm.senha) {
      setErrorMessage('Preencha todos os campos obrigatórios.');
      return;
    }

    console.log('Dados enviados:', empresaForm); // Debug: Verifique os dados enviados

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (modalType === 'edit' && selectedEmpresa) {
      const empresaData = { ...empresaForm };
      delete empresaData.senha; // Remove 'senha' ao editar

      axios.put(`${process.env.REACT_APP_API_URL}/api/empresas/${selectedEmpresa.id}`, empresaData, config)
        .then(response => {
          // Atualiza a lista de empresas com os dados atualizados
          setEmpresas(empresas.map(emp => emp.id === selectedEmpresa.id ? response.data : emp));
          setSuccessMessage('Empresa atualizada com sucesso!');
          setShowSnackbar(true);
          handleCloseModal();
        })
        .catch(error => {
          if (error.response) {
            console.error('Erro ao editar empresa:', error.response.data);
            setErrorMessage(`Erro: ${error.response.data.message || 'Não foi possível editar a empresa.'}`);
          } else if (error.request) {
            console.error('Erro ao editar empresa:', error.request);
            setErrorMessage('Erro: Sem resposta do servidor. Verifique sua conexão.');
          } else {
            console.error('Erro ao editar empresa:', error.message);
            setErrorMessage(`Erro: ${error.message}`);
          }
        });
    } else if (modalType === 'add') {
      axios.post(`${process.env.REACT_APP_API_URL}/api/empresas`, empresaForm, config)
        .then(response => {
          setSuccessMessage('Empresa adicionada com sucesso!');
          setShowSnackbar(true);
          handleCloseModal();
          loadEmpresas(); // Recarrega a lista de empresas
        })
        .catch(error => {
          if (error.response) {
            console.error('Erro ao adicionar empresa:', error.response.data);
            setErrorMessage(`Erro: ${error.response.data.message || 'Não foi possível adicionar a empresa.'}`);
          } else if (error.request) {
            console.error('Erro ao adicionar empresa:', error.request);
            setErrorMessage('Erro: Sem resposta do servidor. Verifique sua conexão.');
          } else {
            console.error('Erro ao adicionar empresa:', error.message);
            setErrorMessage(`Erro: ${error.message}`);
          }
        });
    }
  };

  // Função para remover empresa
  const handleRemoveEmpresa = () => {
    if (selectedEmpresa && selectedEmpresa.id) {
      axios.delete(`${process.env.REACT_APP_API_URL}/api/empresas/${selectedEmpresa.id}`)
        .then(() => {
          setEmpresas(empresas.filter(empresa => empresa.id !== selectedEmpresa.id));
          setSuccessMessage('Empresa removida com sucesso!');
          setShowSnackbar(true);
          handleCloseModal();
        })
        .catch(error => {
          if (error.response) {
            console.error('Erro ao remover empresa:', error.response.data);
            setErrorMessage(`Erro: ${error.response.data.message || 'Não foi possível remover a empresa.'}`);
          } else if (error.request) {
            console.error('Erro ao remover empresa:', error.request);
            setErrorMessage('Erro: Sem resposta do servidor. Verifique sua conexão.');
          } else {
            console.error('Erro ao remover empresa:', error.message);
            setErrorMessage(`Erro: ${error.message}`);
          }
        });
    } else {
      setErrorMessage('Nenhuma empresa selecionada para remover.');
    }
  };

  // Alternar visibilidade da senha
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={() => handleOpenModal('add')}>Adicionar Empresa</Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome da Empresa</TableCell>
            <TableCell>CNPJ</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Início Assinatura</TableCell>
            <TableCell>Fim Assinatura</TableCell>
            <TableCell>Status Assinatura</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {empresas.map((empresa) => (
            <TableRow key={empresa.id}>
              <TableCell>{empresa.nome}</TableCell>
              <TableCell>{empresa.cnpj}</TableCell>
              <TableCell>{empresa.telefone}</TableCell>
              <TableCell>{empresa.email}</TableCell>
              <TableCell>{new Date(empresa.data_inicio_assinatura).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(empresa.data_fim_assinatura).toLocaleDateString()}</TableCell>
              <TableCell>{empresa.status_assinatura}</TableCell>
              <TableCell>
                <Tooltip title="Visualizar">
                  <IconButton color="info" onClick={() => handleOpenModal('view', empresa)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', empresa)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remover">
                  <IconButton color="error" onClick={() => handleOpenModal('delete', empresa)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Empresa' : modalType === 'edit' ? 'Editar Empresa' : 'Visualizar Empresa'}
          </Typography>

          {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

          <TextField
            label="Nome"
            fullWidth
            value={empresaForm.nome}
            onChange={(e) => setEmpresaForm({ ...empresaForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
            disabled={modalType === 'view'}
          />
          <TextField
            label="CNPJ"
            fullWidth
            value={empresaForm.cnpj}
            onChange={(e) => setEmpresaForm({ ...empresaForm, cnpj: e.target.value })}
            sx={{ marginBottom: 2 }}
            disabled={modalType === 'view'}
          />
          <TextField
            label="Endereço"
            fullWidth
            value={empresaForm.endereco}
            onChange={(e) => setEmpresaForm({ ...empresaForm, endereco: e.target.value })}
            sx={{ marginBottom: 2 }}
            disabled={modalType === 'view'}
          />
          <TextField
            label="Telefone"
            fullWidth
            value={empresaForm.telefone}
            onChange={(e) => setEmpresaForm({ ...empresaForm, telefone: e.target.value })}
            sx={{ marginBottom: 2 }}
            disabled={modalType === 'view'}
          />
          <TextField
            label="Email"
            fullWidth
            value={empresaForm.email}
            onChange={(e) => setEmpresaForm({ ...empresaForm, email: e.target.value })}
            sx={{ marginBottom: 2 }}
            disabled={modalType === 'view'}
          />

          {modalType !== 'view' && modalType !== 'edit' && (
            <TextField
              label="Senha"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={empresaForm.senha}
              onChange={(e) => setEmpresaForm({ ...empresaForm, senha: e.target.value })}
              sx={{ marginBottom: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          <TextField
            label="Data de Início da Assinatura"
            fullWidth
            type="date"
            value={empresaForm.data_inicio_assinatura}
            onChange={(e) => setEmpresaForm({ ...empresaForm, data_inicio_assinatura: e.target.value })}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{ shrink: true }}
            disabled={modalType === 'view'}
          />
          <TextField
            label="Data de Fim da Assinatura"
            fullWidth
            type="date"
            value={empresaForm.data_fim_assinatura}
            onChange={(e) => setEmpresaForm({ ...empresaForm, data_fim_assinatura: e.target.value })}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{ shrink: true }}
            disabled={modalType === 'view'}
          />

          <FormControl fullWidth sx={{ marginBottom: 2 }} disabled={modalType === 'view'}>
            <InputLabel>Status da Assinatura</InputLabel>
            <Select
              value={empresaForm.status_assinatura}
              onChange={(e) => setEmpresaForm({ ...empresaForm, status_assinatura: e.target.value })}
              label="Status da Assinatura"
            >
              <MenuItem value="ativa">Ativa</MenuItem>
              <MenuItem value="expirada">Expirada</MenuItem>
              {/* Adicione outros status se necessário */}
            </Select>
          </FormControl>

          {modalType !== 'view' && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" onClick={handleSubmitForm}>
                {modalType === 'add' ? 'Adicionar' : 'Salvar'}
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ marginLeft: 2 }}>Fechar</Button>
            </Box>
          )}
        </Box>
      </Modal>

      {modalType === 'delete' && selectedEmpresa && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', width: '30%', marginTop: '10%' }}>
            <Typography variant="h6" align="center">Deseja remover a empresa?</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
              <Button variant="contained" color="error" onClick={handleRemoveEmpresa}>
                Remover
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ marginLeft: 2 }}>Cancelar</Button>
            </Box>
          </Box>
        </Modal>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default EmpresasList;
