import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EmpresaForm = () => {
  const [empresa, setEmpresa] = useState({ nome: '', cnpj: '', endereco: '', telefone: '', email: '' });
  const { id } = useParams(); // Usado para identificar se estamos editando ou criando
  const navigate = useNavigate();

  // Se estivermos editando, buscar os dados da empresa
  useEffect(() => {
    if (id) {
      const fetchEmpresa = async () => {
        const response = await fetch(`/api/empresas/${id}`);
        const data = await response.json();
        setEmpresa(data);
      };
      fetchEmpresa();
    }
  }, [id]);

  // Função para lidar com mudanças nos campos de input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmpresa({ ...empresa, [name]: value });
  };

  // Função para salvar a empresa (POST ou PUT)
  const handleSave = async () => {
    const method = id ? 'PUT' : 'POST'; // POST para criar, PUT para editar
    const url = id ? `/api/empresas/${id}` : '/api/empresas';

    await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(empresa),
    });

    navigate('/empresas'); // Voltar para listagem
  };

  return (
    <Box sx={{ padding: 3 }}>
      <TextField
        label="Nome"
        name="nome"
        value={empresa.nome}
        onChange={handleChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="CNPJ"
        name="cnpj"
        value={empresa.cnpj}
        onChange={handleChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Endereço"
        name="endereco"
        value={empresa.endereco}
        onChange={handleChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Telefone"
        name="telefone"
        value={empresa.telefone}
        onChange={handleChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Email"
        name="email"
        value={empresa.email}
        onChange={handleChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleSave}>
        {id ? 'Salvar Alterações' : 'Cadastrar Empresa'}
      </Button>
    </Box>
  );
};

export default EmpresaForm;
