import React, { useMemo, useState } from 'react';
import { 
  Box, Button, IconButton, Tooltip, Modal, Typography, 
  TextField, Grid, MenuItem, Select 
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { 
  Delete, Edit, Print, SaveAlt, ViewModule, ViewList, Add 
} from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Gera dados simulados para Ordens de Serviço (O.S)
const generateOSData = (numOS) => {
  const tiposServico = ['Manutenção', 'Instalação', 'Configuração'];
  
  return Array.from({ length: numOS }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    tipoServico: tiposServico[i % tiposServico.length],
    classificacao: `Classificação ${i % 3 + 1}`,
    ultimoLancamento: `2024-10-${(i % 30) + 1}`,
    status: i % 2 === 0 ? 'Pendente' : 'Concluído',
  }));
};

const OrdemServicos = () => {
  const [data, setData] = useState(generateOSData(50));
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOS, setSelectedOS] = useState(null);

  const filteredData = useMemo(() => data, [data]);

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID', size: 50 },
    { accessorKey: 'cliente', header: 'Cliente', size: 200 },
    { accessorKey: 'tipoServico', header: 'Tipo de Serviço', size: 250 },
    { accessorKey: 'classificacao', header: 'Classificação', size: 150 },
    { accessorKey: 'ultimoLancamento', header: 'Último Lançamento', size: 150 },
    { accessorKey: 'status', header: 'Status', size: 100 },
    {
      header: 'Ações',
      size: 150,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Tooltip arrow title="Editar">
            <IconButton color="primary" onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Remover">
            <IconButton color="error" onClick={() => handleRemove(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], []);

  const handleEdit = (row) => {
    setSelectedOS(row.original);
    setModalOpen(true);
  };

  const handleRemove = (row) => {
    const updatedData = data.filter((os) => os.id !== row.original.id);
    setData(updatedData);
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Ordens de Serviço', 20, 10);
    doc.autoTable({
      head: [['ID', 'Cliente', 'Tipo de Serviço', 'Classificação', 'Último Lançamento', 'Status']],
      body: data.map((os) => [
        os.id, os.cliente, os.tipoServico, os.classificacao, os.ultimoLancamento, os.status,
      ]),
    });
    doc.save('ordens_de_servico.pdf');
  };

  const handleAddOS = () => {
    setSelectedOS(null);
    setModalOpen(true);
  };

  return (
    <Box
      sx={{
        height: '100vh', // Ocupar 100% da tela sem rolagem
        overflow: 'hidden', // Remove rolagem da página
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        backgroundColor: '#f9f9f9',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddOS}
        >
          Adicionar O.S.
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" color="info" onClick={exportPDF} startIcon={<SaveAlt />}>
            Exportar PDF
          </Button>
          <Button variant="contained" color="secondary" onClick={() => window.print()} startIcon={<Print />}>
            Imprimir
          </Button>
        </Box>
      </Box>

      {/* Contêiner da tabela com rolagem interna */}
      <Box
        sx={{
          flex: 1, // Ocupar o espaço restante da tela
          overflowY: 'auto', // Rolagem apenas na tabela
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <MaterialReactTable columns={columns} data={filteredData} />
      </Box>

      {/* Modal para Adicionar/Editar O.S */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {selectedOS ? 'Editar O.S.' : 'Nova O.S.'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField fullWidth label="Cliente" defaultValue={selectedOS?.cliente || ''} />
            </Grid>
            <Grid item xs={6}>
              <Select fullWidth defaultValue={selectedOS?.tipoServico || ''}>
                <MenuItem value="Manutenção">Manutenção</MenuItem>
                <MenuItem value="Instalação">Instalação</MenuItem>
                <MenuItem value="Configuração">Configuração</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Classificação" defaultValue={selectedOS?.classificacao || ''} />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Último Lançamento" defaultValue={selectedOS?.ultimoLancamento || ''} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Observação" multiline rows={4} />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="contained" color="primary" onClick={() => setModalOpen(false)}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrdemServicos;
