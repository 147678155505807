import React, { useState, useEffect } from 'react';
import {
  Box, Grid, TextField, Button, Select, MenuItem, Typography, Paper,
  FormControl, InputLabel, Checkbox, Collapse, Divider, Snackbar, Alert, FormControlLabel
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const API_BASE_URL = process.env.REACT_APP_API_URL;

const NovoServico = () => {
  const [formData, setFormData] = useState({
    empresa_id: '', nome: '', codigo: '', classificacao: '', descricao: '',
    departamento_id: '', tipo_servico_id: '', grupo_servico_id: '', esfera: '',
    observacao: '', is_parcelado: false
  });
  const [parcelas, setParcelas] = useState([{ numero_parcela: 1, valor: '', data_vencimento: '' }]);
  const [departamentos, setDepartamentos] = useState([]);
  const [tiposServico, setTiposServico] = useState([]);
  const [gruposServico, setGruposServico] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleParcelamentoChange = () => {
    const { quantidadeParcelas, valorParcela, dataPrimeiraParcela } = formData;
    const parcelasGeradas = [];

    if (!quantidadeParcelas || !valorParcela || !dataPrimeiraParcela) {
      return;
    }

    const valorParcelaNum = parseFloat(valorParcela) / quantidadeParcelas;
    let data = new Date(dataPrimeiraParcela);

    for (let i = 1; i <= quantidadeParcelas; i++) {
      parcelasGeradas.push({
        numero_parcela: i,
        valor: valorParcelaNum.toFixed(2),
        data_vencimento: new Date(data).toISOString().split('T')[0] // Formato YYYY-MM-DD
      });

      // Ajusta a data para o mês seguinte
      data.setMonth(data.getMonth() + 1);
    }


    setParcelas(parcelasGeradas);
  };
  const limparParcelamento = () => {
    setParcelas([]);
    setFormData({ ...formData, quantidadeParcelas: 1, valorParcela: '', dataPrimeiraParcela: '' });
  };
  
  

  
  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      
      const servicoResponse = await axios.post(`${API_BASE_URL}/api/servicos`, formData, { headers });
      const servico_id = servicoResponse.data.id;

      if (formData.is_parcelado) {
        for (let parcela of parcelas) {
          await axios.post(`${API_BASE_URL}/api/parcelamentos-servico`, {
            servico_id,
            ...parcela
          }, { headers });
        }
      }
      setSnackbarMessage("Serviço cadastrado com sucesso!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      navigate('/servicos'); // Redireciona para a lista de serviços ou outra página desejada
    } catch (error) {
      console.error("Erro ao cadastrar o serviço:", error);
      setSnackbarMessage("Erro ao cadastrar o serviço.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const [departamentoRes, tipoServicoRes, grupoServicoRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/departamento`, { headers }),
          axios.get(`${API_BASE_URL}/api/tipo-servico`, { headers }),
          axios.get(`${API_BASE_URL}/api/grupo-servicos`, { headers })
        ]);

        setDepartamentos(departamentoRes.data);
        setTiposServico(tipoServicoRes.data);
        setGruposServico(grupoServicoRes.data);
      } catch (error) {
        console.error("Erro ao carregar opções:", error);
      }
    };

    fetchOptions();
  }, []);

  return (
    <Box sx={{ padding: 4, maxWidth: 1400, margin: 'auto', backgroundColor: '#ffffff' }}>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Cadastrar Novo Serviço</Typography>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 3 }}>
        <Grid container spacing={2}>
          {/* Dados Gerais */}
          <Grid item xs={12} sm={6}>
            <TextField label="Nome" name="nome" fullWidth value={formData.nome} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Código" name="codigo" fullWidth value={formData.codigo} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Classificação" name="classificacao" fullWidth value={formData.classificacao} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Departamento</InputLabel>
              <Select name="departamento_id" value={formData.departamento_id} onChange={handleChange}>
                {departamentos.map(departamento => (
                  <MenuItem key={departamento.id} value={departamento.id}>{departamento.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Tipo do Serviço</InputLabel>
              <Select name="tipo_servico_id" value={formData.tipo_servico_id} onChange={handleChange}>
                {tiposServico.map(tipo => (
                  <MenuItem key={tipo.id} value={tipo.id}>{tipo.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Grupo do Serviço</InputLabel>
              <Select name="grupo_servico_id" value={formData.grupo_servico_id} onChange={handleChange}>
                {gruposServico.map(grupo => (
                  <MenuItem key={grupo.id} value={grupo.id}>{grupo.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Esfera" name="esfera" fullWidth value={formData.esfera} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Descrição" name="descricao" fullWidth multiline rows={4} value={formData.descricao} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Observação" name="observacao" fullWidth multiline rows={2} value={formData.observacao} onChange={handleChange} />
          </Grid>
        </Grid>

        {/* Parcelamento */}
        <Divider sx={{ my: 3 }} />
        <FormControlLabel
          control={
            <Checkbox checked={formData.is_parcelado} onChange={(e) => setFormData({ ...formData, is_parcelado: e.target.checked })} />
          }
          label="Este serviço possui parcelamento"
        />
      <Collapse in={formData.is_parcelado}>
  <Grid container spacing={2} sx={{ mt: 2 }}>
    <Grid item xs={12} sm={4}>
      <TextField
        label="Quantidade de Parcelas" type="number" name="quantidadeParcelas"
        fullWidth value={formData.quantidadeParcelas} onChange={(e) => setFormData({ ...formData, quantidadeParcelas: parseInt(e.target.value) || 1 })}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        label="Valor Total" type="number" name="valorParcela" fullWidth
        value={formData.valorParcela} onChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        label="Data Primeira Parcela" type="date" name="dataPrimeiraParcela" fullWidth
        value={formData.dataPrimeiraParcela} onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
  </Grid>
  <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
    <Button variant="outlined" onClick={handleParcelamentoChange}>Gerar Parcelas</Button>
    <Button variant="outlined" color="secondary" onClick={limparParcelamento}>Limpar Parcelas</Button>
  </Box>

{/* Resumo das Parcelas */}
<Typography variant="subtitle1" sx={{ mt: 2 }}>Resumo do Parcelamento</Typography>
{parcelas.length > 0 && (
  <Box sx={{ maxHeight: 150, overflowY: 'auto', padding: 1, border: '1px solid #ccc', borderRadius: 1 }}>
    <ul>
      {parcelas.map((parcela, index) => (
        <li key={index}>
          Parcela {parcela.numero_parcela}: R$ {parcela.valor} - Vencimento: {new Date(parcela.data_vencimento).toLocaleDateString('pt-BR')}
        </li>
      ))}
    </ul>
  </Box>
)}

</Collapse>

        {/* Botões */}
        <Divider sx={{ my: 3 }} />
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Salvar</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>Voltar</Button>
          </Grid>
        </Grid>

        {/* Snackbar */}
        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
          <Alert onClose={() => setShowSnackbar(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default NovoServico;
