import React, { useState } from 'react';
import { Box, Typography, Modal, IconButton, TextField, Button, Card, CardContent, CardActionArea } from '@mui/material'; // Adicionado Button na importação
import { WhatsApp as WhatsAppIcon, Email as EmailIcon, Telegram as TelegramIcon, Cloud as WebhookIcon, Close as CloseIcon } from '@mui/icons-material';
import { CalendarToday as GoogleCalendarIcon, Payment as StripeIcon, VideoCall as ZoomIcon, Message as SlackIcon } from '@mui/icons-material';

const Integracoes = () => {
  const [openWhatsAppModal, setOpenWhatsAppModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openTelegramModal, setOpenTelegramModal] = useState(false);
  const [openWebhookModal, setOpenWebhookModal] = useState(false);
  const [openSlackModal, setOpenSlackModal] = useState(false);
  const [openGoogleCalendarModal, setOpenGoogleCalendarModal] = useState(false);
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const [openZoomModal, setOpenZoomModal] = useState(false);

  const handleOpenModal = (type) => {
    switch (type) {
      case 'whatsapp':
        setOpenWhatsAppModal(true);
        break;
      case 'email':
        setOpenEmailModal(true);
        break;
      case 'telegram':
        setOpenTelegramModal(true);
        break;
      case 'webhook':
        setOpenWebhookModal(true);
        break;
      case 'slack':
        setOpenSlackModal(true);
        break;
      case 'google-calendar':
        setOpenGoogleCalendarModal(true);
        break;
      case 'stripe':
        setOpenStripeModal(true);
        break;
      case 'zoom':
        setOpenZoomModal(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setOpenWhatsAppModal(false);
    setOpenEmailModal(false);
    setOpenTelegramModal(false);
    setOpenWebhookModal(false);
    setOpenSlackModal(false);
    setOpenGoogleCalendarModal(false);
    setOpenStripeModal(false);
    setOpenZoomModal(false);
  };

  const integrations = [
    { type: 'whatsapp', icon: <WhatsAppIcon fontSize="large" />, title: 'WhatsApp' },
    { type: 'email', icon: <EmailIcon fontSize="large" />, title: 'E-mail' },
    { type: 'telegram', icon: <TelegramIcon fontSize="large" />, title: 'Telegram' },
    { type: 'webhook', icon: <WebhookIcon fontSize="large" />, title: 'Webhook' },
    { type: 'google-calendar', icon: <GoogleCalendarIcon fontSize="large" />, title: 'Google Calendar' },
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Integrações
      </Typography>
      
      {/* Ícones de Integração com Estilo Card */}
      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
        {integrations.map((integration, index) => (
          <Card key={index} sx={{ width: 150, textAlign: 'center', borderRadius: 2, boxShadow: 3 }}>
            <CardActionArea onClick={() => handleOpenModal(integration.type)} sx={{ padding: 2 }}>
              <CardContent>
                {integration.icon}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {integration.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>

      {/* Modal de Configuração do WhatsApp */}
      <Modal open={openWhatsAppModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar WhatsApp</Typography>
          <TextField label="Número do WhatsApp" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="API Key" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do E-mail */}
      <Modal open={openEmailModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar E-mail</Typography>
          <TextField label="Endereço de E-mail" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Senha" type="password" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Servidor SMTP" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do Telegram */}
      <Modal open={openTelegramModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar Telegram</Typography>
          <TextField label="Token do Bot" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Chat ID" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do Webhook */}
      <Modal open={openWebhookModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar Webhook</Typography>
          <TextField label="URL do Webhook" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Token de Autenticação" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do Slack */}
      <Modal open={openSlackModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar Slack</Typography>
          <TextField label="Token da API" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Canal/Usuário" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do Google Calendar */}
      <Modal open={openGoogleCalendarModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar Google Calendar</Typography>
          <TextField label="Client ID" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Client Secret" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Redirect URL" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do Stripe */}
      <Modal open={openStripeModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar Stripe</Typography>
          <TextField label="Chave Pública (Public Key)" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Chave Secreta (Secret Key)" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="URL de Webhook" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do Zoom */}
      <Modal open={openZoomModal} onClose={handleCloseModal}>
        <Box sx={{ backgroundColor: 'white', padding: 4, width: 400, margin: 'auto', marginTop: '10%', borderRadius: 2, position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Configurar Zoom</Typography>
          <TextField label="ID da API" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Token de Acesso" fullWidth sx={{ marginTop: 2 }} />
          <TextField label="Chave Secreta" fullWidth sx={{ marginTop: 2 }} />
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Salvar
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Integracoes;
