import React, { useState } from 'react';
import {
  Box, Grid, Card, CardContent, Paper, Typography, IconButton, Menu, MenuItem, List, ListItem, ListItemIcon,
  ListItemText, Avatar, Chip, Tooltip as MaterialTooltip, ListItemAvatar
} from '@mui/material';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip as ChartTooltip, Legend, BarChart, Bar, XAxis, YAxis
} from 'recharts';
import { FilterList as FilterListIcon, People, BusinessCenter, Assignment, DoneAll, Gavel, WhatsApp, MailOutline } from '@mui/icons-material';

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChart, setSelectedChart] = useState("All");

  const handleFilterClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = (option) => {
    setSelectedChart(option);
    setAnchorEl(null);
  };

  const displayChart = (chartName) => selectedChart === "All" || selectedChart === chartName;

  const teamMembers = [
    { name: 'Carlos Souza', role: 'Contabilidade', status: 'Disponível', avatar: '/images/carlos.jpg' },
    { name: 'Maria Silva', role: 'Consultoria', status: 'Ocupado', avatar: '/images/maria.jpg' },
    { name: 'João Almeida', role: 'Fiscal', status: 'Ausente', avatar: '/images/joao.jpg' },
    { name: 'Ana Costa', role: 'Pessoal', status: 'Disponível', avatar: '/images/ana.jpg' },
  ];

  // Dados para gráficos
  const atividadesData = [
    { status: 'Pendente', value: 20 },
    { status: 'Em Execução', value: 35 },
    { status: 'Concluída', value: 45 },
  ];

  

  const servicosData = [
    { tipo: 'Contabilidade', value: 40 },
    { tipo: 'Consultoria', value: 30 },
    { tipo: 'Fiscal', value: 20 },
    { tipo: 'Pessoal', value: 10 },
  ];

  const obrigacoesData = [
    { status: 'Pendentes', value: 15 },
    { status: 'Concluídas', value: 45 },
  ];

  const equipeData = [
    { membro: 'Maria', atividades: 40 },
    { membro: 'Carlos', atividades: 30 },
    { membro: 'Ana', atividades: 25 },
    { membro: 'João', atividades: 20 },
  ];

  const clientesSegmentoData = [
    { segmento: 'Comércio', value: 150 },
    { segmento: 'Serviços', value: 100 },
    { segmento: 'Indústria', value: 50 },
  ];

  const regimeTributarioData = [
    { regime: 'Simples Nacional', value: 60 },
    { regime: 'Lucro Presumido', value: 30 },
    { regime: 'Lucro Real', value: 10 },
  ];

  const whatsappData = [
    { status: 'Pendente', value: 25 },
    { status: 'Em Atendimento', value: 50 },
    { status: 'Concluído', value: 40 },
  ];

  const emailData = [
    { status: 'Pendente', value: 30 },
    { status: 'Em Atendimento', value: 40 },
    { status: 'Concluído', value: 20 },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: 'bold', color: '#424242' }}>
          Dashboard Analítico
        </Typography>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon sx={{ fontSize: '24px', color: '#424242' }} />
        </IconButton>
      </Box>

  {/* Menu de Filtros */}
  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose("All")}>
        <MenuItem onClick={() => handleClose("All")}>
          <ListItemIcon><FilterListIcon /></ListItemIcon> Exibir Todos
        </MenuItem>
        <MenuItem onClick={() => handleClose("Atividades")}>
          <ListItemIcon><Assignment /></ListItemIcon> Atividades
        </MenuItem>
        <MenuItem onClick={() => handleClose("Serviços")}>
          <ListItemIcon><BusinessCenter /></ListItemIcon> Serviços
        </MenuItem>
        <MenuItem onClick={() => handleClose("Obrigações")}>
          <ListItemIcon><Gavel /></ListItemIcon> Obrigações
        </MenuItem>
        <MenuItem onClick={() => handleClose("Equipe")}>
          <ListItemIcon><People /></ListItemIcon> Equipe
        </MenuItem>
        <MenuItem onClick={() => handleClose("Clientes")}>
          <ListItemIcon><DoneAll /></ListItemIcon> Clientes
        </MenuItem>
        <MenuItem onClick={() => handleClose("WhatsApp")}>
          <ListItemIcon><WhatsApp /></ListItemIcon> WhatsApp
        </MenuItem>
        <MenuItem onClick={() => handleClose("Email")}>
          <ListItemIcon><MailOutline /></ListItemIcon> E-mail
        </MenuItem>
      </Menu>

      {/* Seções de Gráficos */}
      <Grid container spacing={4}>
        {/* Gráficos de Atividades */}
        {displayChart("Atividades") && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                Atividades por Status
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie data={atividadesData} dataKey="value" nameKey="status" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                    {atividadesData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={['#ffb74d', '#42a5f5', '#66bb6a'][index % 3]} />
                    ))}
                  </Pie>
                  <ChartTooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}

        {/* Gráficos de Serviços */}
        {displayChart("Serviços") && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                Serviços por Tipo
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie data={servicosData} dataKey="value" nameKey="tipo" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                    {servicosData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={['#42a5f5', '#ff7043', '#66bb6a', '#ffb74d'][index % 4]} />
                    ))}
                  </Pie>
                  <ChartTooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}

        {/* Gráfico de Obrigações */}
        {displayChart("Obrigações") && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                Obrigações por Status
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie data={obrigacoesData} dataKey="value" nameKey="status" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                    {obrigacoesData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={['#ff7043', '#66bb6a'][index % 2]} />
                    ))}
                  </Pie>
                  <ChartTooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}

        {/* Gráficos de Equipe */}
        {displayChart("Equipe") && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                Atividades por Membro da Equipe
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={equipeData}>
                  <XAxis dataKey="membro" />
                  <YAxis />
                  <Bar dataKey="atividades" fill="#42a5f5" />
                  <ChartTooltip />
                  <Legend />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}

        {/* Gráfico de Clientes */}
        {displayChart("Clientes") && (
          <>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                  Clientes por Segmento
                </Typography>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie data={clientesSegmentoData} dataKey="value" nameKey="segmento" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                      {clientesSegmentoData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={['#42a5f5', '#ff7043', '#66bb6a'][index % 3]} />
                      ))}
                    </Pie>
                    <ChartTooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                  Clientes por Regime Tributário
                </Typography>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie data={regimeTributarioData} dataKey="value" nameKey="regime" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                      {regimeTributarioData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={['#42a5f5', '#ff7043', '#66bb6a'][index % 3]} />
                      ))}
                    </Pie>
                    <ChartTooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
          </>
        )}
        

        {/* Gráfico de WhatsApp */}
        {displayChart("WhatsApp") && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                Status de Conversas no WhatsApp
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie data={whatsappData} dataKey="value" nameKey="status" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                    {whatsappData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={['#ffb74d', '#42a5f5', '#66bb6a'][index % 3]} />
                    ))}
                  </Pie>
                  <ChartTooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}
     {/* Gráfico de E-mail */}
     {displayChart("Email") && (
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ padding: '20px', boxShadow: 3, borderRadius: '12px', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
                Status de Atendimentos por E-mail
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie data={emailData} dataKey="value" nameKey="status" cx="50%" cy="50%" outerRadius={80} fill="#42a5f5" label>
                    {emailData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={['#ffb74d', '#42a5f5', '#66bb6a'][index % 3]} />
                    ))}
                  </Pie>
                  <ChartTooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}
     
   
    </Grid>
    {/* Seção de Cards de Equipe */}
    <Typography variant="h5" sx={{ mt: 5, mb: 3, fontWeight: 'bold', color: '#424242' }}>
        Status da Equipe
      </Typography>
      <Grid container spacing={3}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: 3 }}>
              <Avatar src={member.avatar} alt={member.name} sx={{ width: 56, height: 56, mr: 2 }} />
              <CardContent>
                <Typography variant="h6">{member.name}</Typography>
                <Typography variant="body2" color="textSecondary">{member.role}</Typography>
                <Chip
                  label={member.status}
                  color={
                    member.status === 'Disponível' ? 'success' :
                    member.status === 'Ocupado' ? 'warning' : 'default'
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>

    
  );
};

export default Dashboard;
