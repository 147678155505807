// src/pages/Perfil.js
import React, { useState, useEffect } from 'react';
import {
  Grid, TextField, Button, Box, Typography, Alert
} from '@mui/material';
import axios from 'axios';

const Perfil = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [dataInicioAssinatura, setDataInicioAssinatura] = useState('');
  const [dataFimAssinatura, setDataFimAssinatura] = useState('');
  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Pegar dados do usuário logado
  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    if (usuario) {
      setNome(usuario.nome);
      setEmail(usuario.email);
      setEmpresa(usuario.empresa);
      setDataInicioAssinatura(usuario.data_inicio_assinatura);
      setDataFimAssinatura(usuario.data_fim_assinatura);
    }
  }, []);

  // Função para alterar a senha
  const handleAlterarSenha = async (e) => {
    e.preventDefault();
    
    // Adiciona log para verificar se a função está sendo chamada
    console.log("Tentativa de alterar senha");

    if (novaSenha !== confirmarNovaSenha) {
      setErrorMessage('A nova senha e a confirmação de senha não correspondem.');
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/alterar-senha`, {
        senhaAtual,
        novaSenha
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      console.log("Resposta do servidor:", response.data);

      setSuccessMessage(response.data.message);
      setErrorMessage('');
      setSenhaAtual('');
      setNovaSenha('');
      setConfirmarNovaSenha('');
    } catch (error) {
      console.log("Erro ao alterar a senha:", error);
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Erro ao alterar a senha. Tente novamente.');
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Perfil do Usuário
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Nome"
            value={nome}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            value={email}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Empresa"
            value={empresa}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Início da Assinatura"
            value={dataInicioAssinatura}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Fim da Assinatura"
            value={dataFimAssinatura}
            fullWidth
            disabled
          />
        </Grid>

        {/* Campo para alterar a senha */}
        <Grid item xs={12}>
          <Typography variant="h6">Alterar Senha</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Senha Atual"
            type="password"
            value={senhaAtual}
            onChange={(e) => setSenhaAtual(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Nova Senha"
            type="password"
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Confirmar Nova Senha"
            type="password"
            value={confirmarNovaSenha}
            onChange={(e) => setConfirmarNovaSenha(e.target.value)}
            fullWidth
          />
        </Grid>

        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}
        {successMessage && (
          <Grid item xs={12}>
            <Alert severity="success">{successMessage}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleAlterarSenha}>
            Alterar Senha
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Perfil;
