import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, IconButton, Tooltip, Modal, Typography, TextField, Chip, CircularProgress, Snackbar, Alert } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Delete, Edit, Visibility, SaveAlt, NearMeOutlined, Print } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

const API_URL = `${process.env.REACT_APP_API_URL}/api/obrigacoes`;

const Servicos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedServico, setSelectedServico] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  // Função para buscar dados das obrigações
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.length === 0) {
          setErrorMessage('Nenhum dado encontrado.');
        }
        setData(response.data); // Mesmo vazio, define o estado
        setLoading(false); // Carregamento concluído
      } catch (error) {
        setErrorMessage('Erro ao buscar dados das obrigações');
        setLoading(false); // Carregamento concluído com erro
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };
    fetchData();
  }, [navigate]);
  

  const filteredData = useMemo(() => {
    if (!searchTerm) return data;
    return data.filter(servico =>
      servico.nome_obrigacao.toLowerCase().includes(searchTerm.toLowerCase()) ||
      servico.descricao_parcelamento?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  const columns = useMemo(() => [
    { accessorKey: 'codigo', header: 'Código', size: 100 },
    { accessorKey: 'nome_obrigacao', header: 'Nome da Obrigação', size: 200 },
    { accessorKey: 'departamento', header: 'Departamento', size: 150 },
    { accessorKey: 'tipo_servico', header: 'Tipo de Serviço', size: 150 },
    { accessorKey: 'prioridade', header: 'Prioridade', size: 100 },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => (
        <Chip label={cell.getValue() === 1 ? 'Ativo' : 'Inativo'} color={cell.getValue() === 1 ? 'success' : 'default'} />
      ),
    },
    {
      header: 'Ações',
      size: 150,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '-10px' }}>
          <Tooltip title="Visualizar">
            <IconButton color="info" onClick={() => handleView(row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton color="primary" onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remover">
            <IconButton color="error" onClick={() => handleRemove(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], []);

  const handleView = (row) => {
    setSelectedServico(row.original);
    setModalContent('view');
    setModalOpen(true);
  };

  const handleEdit = (row) => {
    setSelectedServico(row.original);
    setModalContent('edit');
    setModalOpen(true);
  };

  const handleRemove = (row) => {
    setSelectedServico(row.original);
    setModalContent('remove');
    setModalOpen(true);
  };

  return (
    <Box sx={{ padding: 1, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Button variant="outlined" color="primary" onClick={() => navigate('/NovoServico')} startIcon={<NearMeOutlined />} sx={{ textTransform: 'none' }}>
              Adicionar
            </Button>
           
           
            <Button variant="contained" color="secondary" onClick={() => window.print()} startIcon={<Print />} sx={{ textTransform: 'none' }}>
              Imprimir
            </Button>
          </Box>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <MaterialReactTable columns={columns} data={filteredData} />

          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => setShowSnackbar(false)}
            message={successMessage}
          />
        </>
      )}
    </Box>
  );
};

export default Servicos;
