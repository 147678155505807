import React, { useState, useEffect } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Modal, TextField, Typography, Snackbar, IconButton
} from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';

const HorarioAcesso = () => {
  const [horarios, setHorarios] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [horarioForm, setHorarioForm] = useState({
    nome_periodo: '',
    segunda_inicio_exp: '',
    segunda_fim_exp: '',
    terca_inicio_exp: '',
    terca_fim_exp: '',
    quarta_inicio_exp: '',
    quarta_fim_exp: '',
    quinta_inicio_exp: '',
    quinta_fim_exp: '',
    sexta_inicio_exp: '',
    sexta_fim_exp: '',
    sabado_inicio_exp: '',
    sabado_fim_exp: '',
    domingo_inicio_exp: '',
    domingo_fim_exp: ''
  });
  const [modalType, setModalType] = useState('add'); // 'add' ou 'edit'
  const [selectedHorario, setSelectedHorario] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    loadHorarios();
  }, []);

  // Função para carregar os horários da API
  const loadHorarios = async () => {
    try {
      const token = localStorage.getItem('token'); // Pega o token do localStorage
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/horarios-acesso`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHorarios(response.data);
    } catch (error) {
      console.error('Erro ao carregar horários de acesso:', error);
    }
  };

  // Função para abrir o modal de criação ou edição
  const handleOpenModal = (type, horario = null) => {
    setModalType(type);
    if (horario) {
      setSelectedHorario(horario);
      setHorarioForm(horario);
    } else {
      setSelectedHorario(null);
      setHorarioForm({
        nome_periodo: '',
        segunda_inicio_exp: '',
        segunda_fim_exp: '',
        terca_inicio_exp: '',
        terca_fim_exp: '',
        quarta_inicio_exp: '',
        quarta_fim_exp: '',
        quinta_inicio_exp: '',
        quinta_fim_exp: '',
        sexta_inicio_exp: '',
        sexta_fim_exp: '',
        sabado_inicio_exp: '',
        sabado_fim_exp: '',
        domingo_inicio_exp: '',
        domingo_fim_exp: ''
      });
    }
    setOpenModal(true);
  };

  // Fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Submeter o formulário
  const handleSubmit = async () => {
    const url = modalType === 'add'
      ? `${process.env.REACT_APP_API_URL}/api/horarios-acesso`
      : `${process.env.REACT_APP_API_URL}/api/horarios-acesso/${selectedHorario.id}`;
    const method = modalType === 'add' ? 'post' : 'put';
    const token = localStorage.getItem('token');

    try {
      const response = await axios[method](url, horarioForm, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccessMessage('Horário de acesso salvo com sucesso.');
      loadHorarios();
      handleCloseModal();
      setShowSnackbar(true);
    } catch (error) {
      console.error('Erro ao salvar horário de acesso:', error);
      setErrorMessage('Erro ao salvar horário de acesso.');
      setShowSnackbar(true);
    }
  };

  // Função para excluir um horário de acesso
  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/horarios-acesso/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccessMessage('Horário de acesso excluído com sucesso.');
      loadHorarios();
      setShowSnackbar(true);
    } catch (error) {
      setErrorMessage('Erro ao excluir horário de acesso.');
      setShowSnackbar(true);
    }
  };

  // Atualizar o formulário de horário conforme o usuário digita
  const handleHorarioChange = (day, field, value) => {
    setHorarioForm((prevForm) => ({
      ...prevForm,
      [`${day}_${field}`]: value
    }));
  };

  const daysOfWeek = [
    { day: 'segunda', label: 'Segunda-feira' },
    { day: 'terca', label: 'Terça-feira' },
    { day: 'quarta', label: 'Quarta-feira' },
    { day: 'quinta', label: 'Quinta-feira' },
    { day: 'sexta', label: 'Sexta-feira' },
    { day: 'sabado', label: 'Sábado' },
    { day: 'domingo', label: 'Domingo' }
  ];

  return (
    <Box>
      {/* Botão para abrir o modal e adicionar um novo horário */}
      <Button variant="contained" color="primary" onClick={() => handleOpenModal('add')}>
        Adicionar Horário de Acesso
      </Button>

      {/* Tabela de horários */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Período</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {horarios.map(horario => (
            <TableRow key={horario.id}>
              <TableCell>{horario.nome_periodo}</TableCell>
              <TableCell>
                <IconButton color="primary" onClick={() => handleOpenModal('edit', horario)}>
                  <EditIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDelete(horario.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal para adicionar ou editar horário */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: 'white',
            margin: 'auto',
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            maxWidth: '600px',
            width: '100%',
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
            {modalType === 'add' ? 'Adicionar Horário de Acesso' : 'Editar Horário de Acesso'}
          </Typography>

          <TextField
            label="Nome do Período"
            fullWidth
            value={horarioForm.nome_periodo}
            onChange={(e) => setHorarioForm({ ...horarioForm, nome_periodo: e.target.value })}
            sx={{ marginBottom: 3 }}
            required
          />

          {/* Campos de horário para cada dia da semana */}
          <Box display="flex" flexDirection="column" gap={3}>
            {daysOfWeek.map(({ day, label }) => (
              <Box key={day} display="flex" alignItems="center" gap={2}>
                <Typography sx={{ width: '120px' }}>{label}</Typography>
                <TextField
                  label="Início Expediente"
                  type="time"
                  value={horarioForm[`${day}_inicio_exp`] || ''}
                  onChange={(e) => handleHorarioChange(day, 'inicio_exp', e.target.value)}
                  sx={{ width: '200px' }}
                />
                <TextField
                  label="Fim Expediente"
                  type="time"
                  value={horarioForm[`${day}_fim_exp`] || ''}
                  onChange={(e) => handleHorarioChange(day, 'fim_exp', e.target.value)}
                  sx={{ width: '200px' }}
                />
              </Box>
            ))}
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginTop: 3, borderRadius: '8px' }}
          >
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      {/* Snackbar de feedback para sucesso ou erro */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage || errorMessage}
      />
    </Box>
  );
};

export default HorarioAcesso;
