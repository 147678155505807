import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, TextField, Select, MenuItem, Typography, Snackbar, Alert, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as ViewIcon, Close as CloseIcon } from '@mui/icons-material';
import Calendar from 'react-calendar';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import './CalendarStyles.css';  // Arquivo CSS que contém o estilo para os dias com feriados

const Feriados = () => {
  const [feriados, setFeriados] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('add'); // 'add', 'edit', 'view'
  const [feriadoForm, setFeriadoForm] = useState({ nome: '', tipo: 'Nacional' });
  const [selectedFeriado, setSelectedFeriado] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    loadFeriados();
  }, []);

  const loadFeriados = () => {
    const token = localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/api/feriados`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setFeriados(response.data))
      .catch(() => setErrorMessage('Erro ao carregar feriados.'));
  };

  // Formatar a data no formato dd/mm/yyyy
  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setModalType('add');
    setOpenModal(true);
  };

  const handleOpenModal = (type, feriado = null) => {
    setModalType(type);
    setSelectedFeriado(feriado);
    if (feriado) {
      setFeriadoForm({ nome: feriado.nome, tipo: feriado.tipo });
      setSelectedDate(new Date(feriado.data));
    } else {
      setFeriadoForm({ nome: '', tipo: 'Nacional' });
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFeriadoForm({ nome: '', tipo: 'Nacional' });
    setSelectedFeriado(null);
  };

  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!feriadoForm.nome || !feriadoForm.tipo) {
      setErrorMessage('Preencha todos os campos.');
      return;
    }

    const formData = { nome: feriadoForm.nome, tipo: feriadoForm.tipo, data: selectedDate.toISOString().split('T')[0] };

    if (modalType === 'edit' && selectedFeriado) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/feriados/${selectedFeriado.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Feriado atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadFeriados();
        })
        .catch(() => setErrorMessage('Erro ao atualizar feriado.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/feriados`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Feriado cadastrado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadFeriados();
        })
        .catch(() => setErrorMessage('Erro ao cadastrar feriado.'));
    }
  };

  const handleDeleteFeriado = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/feriados/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Feriado deletado com sucesso.');
        setShowSnackbar(true);
        loadFeriados();
      })
      .catch(() => setErrorMessage('Erro ao deletar feriado.'));
  };

  // Função para marcar dias no calendário que possuem feriados
  const isFeriado = (date) => {
    const diaFormatado = date.toISOString().split('T')[0];
    return feriados.some(feriado => feriado.data === diaFormatado);
  };

  return (
    <Box display="flex">
      {/* Calendário */}
      <Box flex="1" padding={2}>
        <Calendar
          value={selectedDate}
          onClickDay={handleDateClick}
          tileClassName={({ date }) => isFeriado(date) ? 'highlight' : null}
        />
      </Box>

      {/* Lista de Feriados */}
      <Box flex="1" padding={2} style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Typography variant="h6">Lista de Feriados</Typography>
        <List>
          {feriados.map((feriado) => (
            <ListItem key={feriado.id}>
              <ListItemText primary={`${feriado.nome} (${feriado.tipo})`} secondary={formatarData(feriado.data)} />
              <Tooltip title="Visualizar">
                <IconButton color="primary" onClick={() => handleOpenModal('view', feriado)}>
                  <ViewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton color="primary" onClick={() => handleOpenModal('edit', feriado)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remover">
                <IconButton color="error" onClick={() => handleDeleteFeriado(feriado.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Modal para Adicionar/Editar/Visualizar */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Feriado' : modalType === 'edit' ? 'Editar Feriado' : 'Visualizar Feriado'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Feriado"
            fullWidth
            value={feriadoForm.nome}
            disabled={modalType === 'view'}
            onChange={(e) => setFeriadoForm({ ...feriadoForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Select
            label="Tipo de Feriado"
            fullWidth
            value={feriadoForm.tipo}
            disabled={modalType === 'view'}
            onChange={(e) => setFeriadoForm({ ...feriadoForm, tipo: e.target.value })}
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="Nacional">Nacional</MenuItem>
            <MenuItem value="Estadual">Estadual</MenuItem>
            <MenuItem value="Municipal">Municipal</MenuItem>
          </Select>

          {modalType !== 'view' && (
            <Button variant="contained" color="primary" onClick={handleSubmitForm}>
              {modalType === 'add' ? 'Adicionar' : 'Salvar'}
            </Button>
          )}
        </Box>
      </Modal>

      {/* Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default Feriados;
