import React from 'react';
import { Navigate } from 'react-router-dom';

// Verifica se o usuário logado é admin ou superadmin
const ProtectedAdminRoute = ({ children }) => {
  const usuario = JSON.parse(localStorage.getItem('usuario'));

  // Verifica se o usuário não existe ou não é admin ou superadmin
  if (!usuario || (usuario.tipo_usuario !== 'admin' && usuario.tipo_usuario !== 'superadmin')) {
    return <Navigate to="/not-authorized" />; // Redireciona se não for admin ou superadmin
  }

  return children; // Permite o acesso para admin ou superadmin
};

export default ProtectedAdminRoute;
