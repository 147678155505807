import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Typography, Avatar, Badge, Menu, MenuItem, Divider, Modal, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import SupportIcon from '@mui/icons-material/HeadsetMic';
import logo from './bg_logo.png'; // Logo da empresa
import { useNavigate } from 'react-router-dom';

const Header = ({ toggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null); // Estado para abrir o menu
  const [userName, setUserName] = useState('');   // Estado para armazenar o nome do usuário
  const [userRole, setUserRole] = useState('');   // Estado para armazenar o tipo de usuário
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false); // Estado para abrir o modal de logout
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  // Função para buscar o nome e tipo do usuário
  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario')); // Busca o usuário do localStorage
    if (usuario) {
      setUserName(usuario.nome || 'Usuário');  // Atualiza o estado com o nome do usuário
      setUserRole(usuario.tipo_usuario || 'usuario');  // Define o tipo de usuário (ex: superadmin, gerente, usuario)
    } else {
      setUserName('Usuário');
      setUserRole('usuario');
    }
  }, []);

  // Função para abrir o menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Função para fechar o menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Função para redirecionar para uma rota
  const handleNavigate = (path) => {
    navigate(path);
    handleMenuClose();
  };

  // Função para mostrar a janela modal de logout
  const handleLogoutClick = () => {
    setLogoutModalOpen(true);  // Abre o modal de logout
  };

  // Função para executar o logout (remover token e redirecionar)
  const handleLogout = () => {
    localStorage.removeItem('token');       // Remove o token
    localStorage.removeItem('usuario');     // Remove os dados do usuário
    setLogoutModalOpen(false);              // Fecha o modal
    navigate('/');                          // Redireciona para a página de login
  };

  // Função para fechar o modal de logout
  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white', color: '#8c52ff', zIndex: 1300 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Lado esquerdo: Botão de encolher + Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Botão para encolher/expandir a sidebar */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebar} // Chama a função de toggle para abrir/fechar a sidebar
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ height: 40, mr: 2 }}
          />
          <Typography variant="h9" noWrap component="div" sx={{ color: 'purple', fontWeight: 'bold' }}>
            {userName} {/* Exibe o nome do usuário */}
          </Typography>
        </Box>

        {/* Lado direito: Avatar, Notificações, Chat e Suporte */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Ícone de Notificações */}
          <IconButton color="inherit">
            <Badge badgeContent={4} color="error">
              <NotificationsIcon sx={{ color: 'orange' }} /> {/* Cor laranja */}
            </Badge>
          </IconButton>

          {/* Ícone de Chat */}
          <IconButton color="inherit">
            <ChatIcon sx={{ color: 'orange' }} /> {/* Cor laranja */}
          </IconButton>

          {/* Ícone de Suporte */}
          <IconButton color="inherit">
            <SupportIcon sx={{ color: 'orange' }} /> {/* Cor laranja */}
          </IconButton>

          {/* Avatar do Usuário */}
          <IconButton onClick={handleMenuOpen}>
            <Avatar sx={{ bgcolor: 'orange' }}>{userName.charAt(0).toUpperCase()}</Avatar> {/* Inicial do nome */}
          </IconButton>
        </Box>

        {/* Menu que aparece ao clicar no Avatar */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => handleNavigate('/perfil')}>
            Perfil
          </MenuItem>

          {/* Exibe "Empresas" apenas se o usuário for superadmin */}
          {userRole === 'superadmin' && (
            <MenuItem onClick={() => handleNavigate('/empresas')}>
              Empresas
            </MenuItem>
          )}

          <Divider />

          {/* Logout */}
          <MenuItem onClick={handleLogoutClick}>
            Sair
          </MenuItem>
        </Menu>

        {/* Modal de Logout */}
        <Modal
          open={isLogoutModalOpen}
          onClose={handleCloseLogoutModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              textAlign: 'center',
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Encerrando sessão
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Você está prestes a encerrar sua sessão. Deseja continuar?
            </Typography>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleLogout}>
                Sim
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCloseLogoutModal}>
                Cancelar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
