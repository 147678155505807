import React, { useState } from 'react';
import {
  Box, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody, Button, Modal,
  Typography, TextField, IconButton, Tooltip, TableContainer, Paper, Divider, Grid
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

const CadastroRegimeTributario = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalEsfera, setModalEsfera] = useState('');
  const [form, setForm] = useState({ nome: '', descricao: '' });
  const [activeTab, setActiveTab] = useState(0);
  const [registros, setRegistros] = useState({
    Federal: [{ id: 1, nome: 'Simples Nacional', descricao: 'Regime Simples' }],
    Estadual: [{ id: 1, nome: 'ICMS', descricao: 'Imposto sobre Circulação' }],
    Municipal: [{ id: 1, nome: 'ISS', descricao: 'Imposto sobre Serviços' }],
  });

  // Função para abrir modal
  const handleOpenModal = (esfera, registro = null) => {
    setModalEsfera(esfera);
    setForm(registro || { nome: '', descricao: '' });
    setOpenModal(true);
  };

  // Função para fechar modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setForm({ nome: '', descricao: '' });
  };

  // Salvar ou atualizar dados
  const handleSave = () => {
    setRegistros(prev => ({
      ...prev,
      [modalEsfera]: form.id
        ? prev[modalEsfera].map(reg => reg.id === form.id ? form : reg)
        : [...prev[modalEsfera], { ...form, id: Date.now() }]
    }));
    handleCloseModal();
  };

  // Deletar registro
  const handleDelete = (esfera, id) => {
    setRegistros(prev => ({
      ...prev,
      [esfera]: prev[esfera].filter(reg => reg.id !== id)
    }));
  };

  // Função para trocar a aba ativa
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const esferas = ['Federal', 'Estadual', 'Municipal'];

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Grid container spacing={2}>
        {/* Coluna de abas laterais */}
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 10, 0.1)',
              '& .MuiTab-root': {
                textTransform: 'none',
                justifyContent: 'flex-start',
                padding: '12px 24px',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              },
              '& .Mui-selected': {
                backgroundColor: '#f5f5f5',
                color: '#8c52ff',
                fontWeight: 'bold',
              }
            }}
          >
            {esferas.map((esfera, index) => (
              <Tab key={esfera} label={esfera} />
            ))}
          </Tabs>
        </Grid>

        {/* Conteúdo das abas */}
        <Grid item xs={9}>
          {esferas.map((esfera, index) => (
            activeTab === index && (
              <Box key={esfera} sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom>{esfera} - Regime Tributário</Typography>
                
                <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenModal(esfera)}
                  >
                    Adicionar {esfera}
                  </Button>
                </Grid>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome do Regime</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {registros[esfera].map((reg, i) => (
                        <TableRow key={i}>
                          <TableCell>{reg.nome}</TableCell>
                          <TableCell>{reg.descricao}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Editar">
                              <IconButton onClick={() => handleOpenModal(esfera, reg)}>
                                <EditIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Deletar">
                              <IconButton onClick={() => handleDelete(esfera, reg.id)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
              </Box>
            )
          ))}
        </Grid>
      </Grid>

      {/* Modal de Cadastro/Edição */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '40%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{modalEsfera} - {form.id ? 'Editar' : 'Adicionar'} Regime</Typography>
          <TextField
            label="Nome do Regime"
            fullWidth
            value={form.nome}
            onChange={(e) => setForm({ ...form, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Descrição"
            fullWidth
            multiline
            value={form.descricao}
            onChange={(e) => setForm({ ...form, descricao: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSave} fullWidth>
            {form.id ? 'Salvar' : 'Adicionar'}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default CadastroRegimeTributario;
