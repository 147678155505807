import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, TextField, Button, Snackbar, IconButton, Tooltip, Alert } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Visibility as ViewIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill'; // Editor de texto avançado
import 'react-quill/dist/quill.snow.css'; // Estilo do editor
import Dropzone from 'react-dropzone'; // Para upload de imagem
import axios from 'axios';

const EmailTemplates = () => {
  const [value, setValue] = useState(0); // Controlador das tabs
  const [templates, setTemplates] = useState([]);
  const [templateForm, setTemplateForm] = useState({ nome: '', assunto: '', conteudo: '', imagem_url: '' });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [modalType, setModalType] = useState('add'); // 'add', 'edit', 'view'
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = () => {
    const token = localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/api/email-templates`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setTemplates(response.data))
      .catch(() => setErrorMessage('Erro ao carregar templates.'));
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setTemplateForm({ nome: '', assunto: '', conteudo: '', imagem_url: '' });
      setModalType('add');
      setSelectedTemplate(null);
    }
  };

  const handleEditTemplate = (template) => {
    setTemplateForm({ nome: template.nome, assunto: template.assunto, conteudo: template.conteudo, imagem_url: template.imagem_url });
    setModalType('edit');
    setSelectedTemplate(template);
    setValue(1); // Muda para a aba de edição
  };

  const handleDeleteTemplate = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/email-templates/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Template removido com sucesso.');
        setShowSnackbar(true);
        loadTemplates();
      })
      .catch(() => setErrorMessage('Erro ao remover template.'));
  };

  const handleDrop = (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setTemplateForm({ ...templateForm, imagem_url: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!templateForm.nome || !templateForm.assunto || !templateForm.conteudo) {
      setErrorMessage('Preencha todos os campos.');
      return;
    }

    const formData = { nome: templateForm.nome, assunto: templateForm.assunto, conteudo: templateForm.conteudo, imagem_url: templateForm.imagem_url };

    if (modalType === 'edit' && selectedTemplate) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/email-templates/${selectedTemplate.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Template atualizado com sucesso.');
          setShowSnackbar(true);
          loadTemplates();
          setValue(0); // Volta para a aba de templates cadastrados
        })
        .catch(() => setErrorMessage('Erro ao atualizar template.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/email-templates`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Template criado com sucesso.');
          setShowSnackbar(true);
          loadTemplates();
          setValue(0); // Volta para a aba de templates cadastrados
        })
        .catch(() => setErrorMessage('Erro ao criar template.'));
    }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {/* Abas para navegar entre os templates cadastrados e o formulário */}
      <Tabs value={value} onChange={handleChangeTab} centered>
        <Tab label="Templates Cadastrados" />
        <Tab label="Adicionar/Editar Template" />
      </Tabs>

      {/* Aba 1: Templates Cadastrados */}
      {value === 0 && (
        <Box padding={2}>
          <Typography variant="h6" gutterBottom>Templates Cadastrados</Typography>
          {templates.length > 0 ? (
            templates.map(template => (
              <Box key={template.id} padding={2} marginBottom={2} border="1px solid #ccc" borderRadius={2}>
                <Typography variant="h6">{template.nome}</Typography>
                <Typography>Assunto: {template.assunto}</Typography>
                {template.imagem_url && <img src={template.imagem_url} alt="Imagem do Cabeçalho" style={{ width: '100px', marginTop: '10px' }} />}
                <Box marginTop={1} display="flex" gap={2}>
                  <Tooltip title="Visualizar">
                    <IconButton color="primary" onClick={() => handleEditTemplate(template)}>
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <IconButton color="primary" onClick={() => handleEditTemplate(template)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover">
                    <IconButton color="error" onClick={() => handleDeleteTemplate(template.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1">Nenhum template cadastrado.</Typography>
          )}
        </Box>
      )}

      {/* Aba 2: Adicionar/Editar Template */}
      {value === 1 && (
        <Box display="flex" padding={2} gap={4}>
          {/* Formulário de Edição/Criação */}
          <Box flex="1">
            <Typography variant="h6" gutterBottom>{modalType === 'add' ? 'Adicionar Template de E-mail' : 'Editar Template de E-mail'}</Typography>

            {errorMessage && <Alert severity="error" sx={{ marginBottom: 2 }}>{errorMessage}</Alert>}

            <TextField
              label="Nome do Template"
              fullWidth
              value={templateForm.nome}
              onChange={(e) => setTemplateForm({ ...templateForm, nome: e.target.value })}
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Assunto"
              fullWidth
              value={templateForm.assunto}
              onChange={(e) => setTemplateForm({ ...templateForm, assunto: e.target.value })}
              sx={{ marginBottom: 2 }}
            />

            <ReactQuill
              value={templateForm.conteudo}
              onChange={(value) => setTemplateForm({ ...templateForm, conteudo: value })}
              style={{ marginBottom: '20px', height: '200px' }}
            />

            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps()} border="1px dashed #ccc" padding={2} textAlign="center" sx={{ marginBottom: 2 }}>
                  <input {...getInputProps()} />
                  <p>Arraste uma imagem aqui ou clique para selecionar</p>
                </Box>
              )}
            </Dropzone>

            {templateForm.imagem_url && (
              <img src={templateForm.imagem_url} alt="Imagem do Cabeçalho" style={{ width: '100px', marginTop: '10px' }} />
            )}

            <Button variant="contained" color="primary" onClick={handleSubmitForm}>
              {modalType === 'add' ? 'Adicionar' : 'Salvar'}
            </Button>
          </Box>

          {/* Prévia do E-mail */}
          <Box flex="1" padding={2} borderLeft="1px solid #ccc">
            <Typography variant="h6">Prévia do E-mail</Typography>
            <Box border="1px solid #ccc" padding={2} borderRadius={2} marginTop={2}>
              {/* Assunto do E-mail */}
              <Typography variant="subtitle1" fontWeight="bold">Assunto: {templateForm.assunto || '(Sem assunto)'}</Typography>

              {/* Imagem do Cabeçalho */}
              {templateForm.imagem_url && (
                <Box marginBottom={2}>
                  <img src={templateForm.imagem_url} alt="Imagem do E-mail" style={{ width: '100%' }} />
                </Box>
              )}

              {/* Corpo do E-mail */}
              <Box marginTop={2} dangerouslySetInnerHTML={{ __html: templateForm.conteudo || '(Corpo do e-mail vazio)' }} />
            </Box>
          </Box>
        </Box>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default EmailTemplates;
