import React, { useMemo, useState, useEffect } from 'react';
import { Box, Button, IconButton, Tooltip, Modal, Typography, TextField, CircularProgress, Snackbar, Alert } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { 
  Delete, Edit, Visibility, Print, SaveAlt, 
  ViewModule, ViewList, Email, Phone, Search, NearMeOutlined 
} from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Usar a variável de ambiente para URL da API
const API_URL = process.env.REACT_APP_API_URL;

// Função para gerar dados de obrigações simuladas
const generateObligationsData = (numClientes) => {
  const obligations = ['Imposto de Renda', 'ISS', 'ICMS', 'Folha de Pagamento', 'Contribuições'];
  return Array.from({ length: numClientes }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    vencimento: `2024-11-${(i % 30) + 1}`,
    status: i % 2 === 0 ? 'Pendente' : 'Concluído',
  }));
};

const Obrigacoes = () => {
  const [data, setData] = useState(generateObligationsData(500));
  const [hasMore, setHasMore] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [viewMode, setViewMode] = useState('table');
  const [selectedObligation, setSelectedObligation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filtroStatus, setFiltroStatus] = useState('todos');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(true); // Adiciona variável de carregamento
  const [errorMessage, setErrorMessage] = useState(''); // Adiciona variável de erro
  const navigate = useNavigate();

  const filteredData = useMemo(() => {
    let filteredObligations = data;
    if (filtroStatus !== 'todos') {
      filteredObligations = filteredObligations.filter(o => o.status === filtroStatus);
    }
    if (searchTerm) {
      filteredObligations = filteredObligations.filter(o =>
        o.cliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
        o.obrigacao.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return filteredObligations;
  }, [data, filtroStatus, searchTerm]);

  // Função para buscar dados das obrigações
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/obrigacoes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.length === 0) {
          setErrorMessage('Nenhum dado encontrado.');
        }
        setData(response.data);
      } catch (error) {
        setErrorMessage('Erro ao buscar dados das obrigações');
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };
    fetchData();
  }, [navigate]);

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID', size: 50 },
    { accessorKey: 'cliente', header: 'Cliente', size: 200 },
    { accessorKey: 'obrigacao', header: 'Obrigação', size: 250 },
    { accessorKey: 'vencimento', header: 'Vencimento', size: 150 },
    { accessorKey: 'status', header: 'Status', size: 100 },
    {
      header: 'Ações',
      size: 150,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Tooltip arrow title="Visualizar">
            <IconButton color="info" onClick={() => handleView(row)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Editar">
            <IconButton color="primary" onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Remover">
            <IconButton color="error" onClick={() => handleRemove(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], []);

  const handleView = (row) => {
    setSelectedObligation(row.original);
    setModalContent('view');
    setModalOpen(true);
  };

  const handleEdit = (row) => {
    setSelectedObligation(row.original);
    setModalContent('edit');
    setModalOpen(true);
  };

  const handleRemove = (row) => {
    setSelectedObligation(row.original);
    setModalContent('remove');
    setModalOpen(true);
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Obrigações Contábeis', 20, 10);
    doc.autoTable({
      head: [['ID', 'Cliente', 'Obrigação', 'Vencimento', 'Status']],
      body: data.map(o => [o.id, o.cliente, o.obrigacao, o.vencimento, o.status]),
    });
    doc.save('obrigacoes.pdf');
  };

  const toggleViewMode = () => setViewMode(viewMode === 'table' ? 'cards' : 'table');

  const renderModalContent = () => {
    if (!selectedObligation) return null;
    // Modal Content
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => navigate('/nova-obrigacoes')} 
              startIcon={<NearMeOutlined />}
              sx={{ textTransform: 'none' }}
            >
              Adicionar
            </Button>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={exportPDF} 
              startIcon={<Print />}
              sx={{ textTransform: 'none' }}
            >
              Imprimir
            </Button>
          </Box>
          
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          
          <MaterialReactTable columns={columns} data={filteredData} />

          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => setShowSnackbar(false)}
            message={successMessage}
          />
        </>
      )}

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
          {renderModalContent()}
        </Box>
      </Modal>
    </Box>
  );
};

export default Obrigacoes;
